import React, { useState } from 'react';
import DummyImage from "../../../../Assets/images/error2.png"
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Svgs from 'Assets/svgs';
import ImageGalleryPopup from 'Pages/Restaurants/Profile/Elements/ImageGalleryPopup';

export default function Slider({ data }) {
    const [openGallery, setOpenGallery] = useState(false);

    return (
        <>
            <div className='relative'>
                {data?.length > 4 && (
                    <div
                        className="flex z-10 absolute rounded-lg px-2.5 cursor-pointer bottom-3 right-3 py-1.5 text-xs gap-1.5 bg-white"
                        onClick={() => setOpenGallery(!openGallery)}
                    >
                        <Svgs.GalleryIcon />
                        Show All Photos
                    </div>
                )}
                {data?.length > 0 ?
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={1}
                        autoplay={true}
                    >
                        {data?.slice(0, 4)?.map((itm, index) => (
                            <SwiperSlide key={index}>
                                <div className='h-[200px]'>
                                    <img className='h-full w-full object-cover rounded-lg' src={itm?.url} alt={index} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper> :
                    <Swiper
                        modules={[Autoplay]}
                        autoplay={true}
                        slidesPerView={1}
                    >
                        <SwiperSlide >
                            <div className='h-[200px]'>
                                <img className='h-full w-full object-cover rounded-lg' src={DummyImage} alt="dummy" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                }
            </div>

            <ImageGalleryPopup
                images={data}
                open={openGallery}
                close={() => { setOpenGallery(false) }}
            />
        </>
    );
}