import { getAdminBusinessBookingsData, getAdminPendingRecievablesPayablesData, payBusinessRemainingDues } from "Adapters/APIs/Admin/finance";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAdminPendingRecievables = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [financeData, setFinanceData] = useState([])
    const [paginationData, setPaginationData] = useState()
    const [businessPay, setBusinessPay] = useState(false)
    const [businessPayLoader, setBusinessPayLoader] = useState(false)
    const [payClicked, setPayClicked] = useState(false)
    const [paidProof, setPaidProof] = useState()
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [totalManagerEarnings, setTotalManagerEarnings] = useState(0);
    const [getBookingsLoader, setGetBookingsLoader] = useState(true)
    const [selectedBusinessData, setSelectedBusinessData] = useState({
        bookings: []
    })

    // get admin finance data
    const getAdminPendingRecievables = () => {
        let payload = {
            page: currentPage,
            per_page: 10,
            type: 'restaurant',
            isPendingRestaurantPayables: true
        }
        const success = (data) => {
            setFinanceData(data?.data)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminPendingRecievablesPayablesData(access_token, payload, success, fail))
    }

    // get single business details
    const getAdminSingleBusinessDetails = (id) => {
        let payload = {
            restaurant_id: id,
        }
        const success = (data) => {
            setSelectedBusinessData(data)
            setGetBookingsLoader(false)
            setSelectedBookings(data?.bookings)
            setTotalManagerEarnings(data?.bookings?.reduce((acc, b) => acc + ((+b?.end_user_transaction?.business_earning + (+b?.end_user_transaction?.tip_amount || 0)) || 0), 0));
        }
        const fail = () => {
            setGetBookingsLoader(false)
        }
        setGetBookingsLoader(true)
        dispatch(getAdminBusinessBookingsData(access_token, payload, success, fail))
    }

    // on prrof handle change
    const onProofChangeHandler = (e) => {
        const { name, files } = e?.target
        setPaidProof(files[0])
        setErrors({ ...errors, [name]: '' })
    }

    // pay business remaining dues
    const payBusinessDues = () => {
        let payload = {
            paid_proof: paidProof,
            paid_amount: totalManagerEarnings,
            reservationIds: selectedBookings?.map(itm => itm?.id)
        }
        const success = (res) => {
            setBusinessPayLoader(false)
            setBusinessPay(false)
            setPayClicked(false)
            setLoader(true)
            setCurrentPage(1)
            getAdminPendingRecievables()
            setPaidProof()
        }
        const fail = () => {
            setBusinessPayLoader(false)
        }
        let requiredFields = { paidProof: paidProof }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setBusinessPayLoader(true)
            dispatch(payBusinessRemainingDues(access_token, payload, success, fail))
        }
    }

    // handle booking click function
    const handleBookingClick = (booking) => {
        const isSelected = selectedBookings?.find((b) => b?.number === booking?.number);

        if (isSelected) {
            const newSelectedBookings = selectedBookings?.filter((b) => b?.number !== booking?.number);
            setSelectedBookings(newSelectedBookings);
            setTotalManagerEarnings(newSelectedBookings?.reduce((acc, b) => acc + ((+b?.end_user_transaction?.business_earning + (+b?.end_user_transaction?.tip_amount || 0)) || 0), 0));
        } else {
            const newSelectedBookings = [...selectedBookings, booking];
            setSelectedBookings(newSelectedBookings);
            setTotalManagerEarnings(newSelectedBookings?.reduce((acc, b) => acc + ((+b?.end_user_transaction?.business_earning + (+b?.end_user_transaction?.tip_amount || 0)) || 0), 0));
        }
    };

    // handle select all bookings or not
    const handleSelectAllBookings = () => {
        if (selectedBookings?.length == selectedBusinessData?.bookings?.length) {
            setSelectedBookings([])
            setTotalManagerEarnings(0)
        } else {
            setSelectedBookings(selectedBusinessData?.bookings);
            const initialTotal = selectedBusinessData?.bookings?.reduce((acc, b) => acc + ((+b?.end_user_transaction?.business_earning + (+b?.end_user_transaction?.tip_amount || 0)) || 0), 0);
            setTotalManagerEarnings(initialTotal);
        }
    }

    useEffect(() => {
        getAdminPendingRecievables()
    }, [currentPage])

    return {
        financeData, navigate, paginationData, loader, currentPage, setCurrentPage, businessPay, setBusinessPay, payBusinessDues, payClicked,
        setPayClicked, paidProof, onProofChangeHandler, businessPayLoader, errors, handleBookingClick, selectedBookings, totalManagerEarnings,
        handleSelectAllBookings, selectedBusinessData, setSelectedBusinessData, getAdminSingleBusinessDetails, getBookingsLoader
    }
}

export default useAdminPendingRecievables