import React from 'react';
import Svgs from 'Assets/svgs';
import { currency } from 'Adapters/variables';
import Button from 'Components/Elements/Button';

const TransactionCard = ({ date, setShowProof, setViewBookings, amount, image, onClick, status, number, onDetailClick, onCashIconClick }) => {
    return (
        <>
            <div className="bg-darkGrey rounded-md shadow-md p-4"
                onClick={() => { onClick && onClick() }}
            >
                <div className="flex flex-col xs:flex-row justify-between gap-2 items-center text-blueGrey">
                    <div className="flex w-full flex-col gap-3">
                        <div className='flex justify-between items-center gap-2'>
                            <span className=" font-semibold text-base">{date}</span>
                            <button className={`${status ? 'status-confirmed' : 'status-not-confirmed'} flex items-center gap-1 px-3 py-1.5 rounded-lg text-sm`}>
                                {
                                    status
                                        ? <>
                                            <Svgs.Tick stroke={'var(--secondary-color)'} />
                                            Confirmed
                                        </>
                                        : "Not Confirmed"
                                }
                            </button>
                        </div>
                        <div className="flex items-center gap-3 flex-1">
                            <div className='flex gap-2 text-primary font-semibold px-3 py-1.5 bg-secondary text-sm rounded-md'>
                                {amount} {currency}
                            </div>
                            <button className='flex p-1.5 cursor-pointer  bg-lightGrey rounded-full'
                                onClick={() => {
                                    setShowProof && setShowProof(image)
                                }}
                            >
                                <Svgs.AttachmentIcon fill={'var(--secondary-color)'} />
                            </button>
                            <button className='flex p-1.5 cursor-pointer bg-lightGrey rounded-full'
                                onClick={() => {
                                    if (number) {
                                        if (onCashIconClick) {
                                            onCashIconClick()
                                        } else {
                                            window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/reciept?number=${number}`, '_blank')
                                        }
                                    }
                                }}
                            >
                                <Svgs.CashIcon width={'20'} height={'20'} fill={'var(--secondary-color)'} />
                            </button>
                        </div>
                        <div className="flex mt-3 lg:flex-row md:flex-col sm:flex-row items-center justify-between w-full gap-4 md:gap-2 lg:gap-4">

                            {!status && <Button customThemeBtn={'view-edit-btn'} className={'w-full'}
                                onClick={onDetailClick && onDetailClick}
                            >
                                View Details
                            </Button>}
                            <Button className={'w-full'}
                                onClick={() => {
                                    setViewBookings && setViewBookings()
                                }}
                            >
                                View Bookings
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransactionCard;