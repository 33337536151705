import { GET_ALL_BUSINESS, GET_TRANSACTION } from "./ActionType";

const initialState = {
    businesses: [],

}

const BusinessReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TRANSACTION:
            return {
                ...state,
                transaction: payload.transactions,
                transaction_pagination: payload?.pagination_data?.meta,
            }
        case GET_ALL_BUSINESS:
            return {
                ...state,
                businesses: payload,
            }
        default:
            return {
                ...state,
            };
    }
}

export default BusinessReducer