import Button from 'Components/Elements/Button'
import React from 'react'
import NotFound from 'Utility/NotFound'

const PendingRestaurantPayablesTable = ({ data, onPayClick }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white border-b border-table bg-primary capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Business Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total Bookings
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total Bill Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Payable Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Payable Tip Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total Payable
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Pay
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary">
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.end_user_transactions_count}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.end_user_transactions_sum_bill_amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.end_user_transactions_sum_business_earning}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.end_user_transactions_sum_tip_amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {(+itm?.end_user_transactions_sum_business_earning + (itm?.end_user_transactions_sum_tip_amount ? +itm?.end_user_transactions_sum_tip_amount : 0))?.toFixed(2)}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                <Button
                                                    customPadding={"px-3 py-[6px]"}
                                                    className={'text-sm'}
                                                    text={'Pay Now'}
                                                    onClick={() => {
                                                        if (onPayClick) {
                                                            onPayClick(itm)
                                                        }
                                                    }} />
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default PendingRestaurantPayablesTable