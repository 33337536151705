import React, { useRef, useState } from 'react';


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Popup from 'Components/Elements/Popup';
import Image from 'Components/Elements/Image';
import Svgs from 'Assets/svgs';

const ImageGalleryPopup = ({ open, close, name, images }) => {
    const sliderRef = useRef(null);

    const next = () => sliderRef.current?.slickNext();
    const previous = () => sliderRef.current?.slickPrev();

    const [sliderOpen, setSliderOpen] = useState(false); // State to open the slider
    const [currentSlide, setCurrentSlide] = useState(0); // State to track the starting image index

    const sliderSettings = {
        infinite: true, // Loop through images
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentSlide,
        arrows: false
    };
    return (
        <>
            {/* Popup containing the image grid */}
            <Popup
                open={open}
                close={() => {
                    if (close) close(false);
                }}
                heading={name}
                removeHeading={name ? false : true}
                size="lg"
            >
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {images?.map((image, ind) => (
                        <Image
                            src={image.url}
                            key={ind}
                            className="rounded-[10px] h-36 w-full sm:h-56 object-cover cursor-pointer"
                            customLoaderClass="rounded-[10px] h-36 sm:h-56"
                            customLoaderHeight={'h-36 sm:h-56'}
                            alt=""
                            onClick={() => {
                                setCurrentSlide(ind); // Set the clicked image index
                                setSliderOpen(true); // Open the slider
                            }}
                        />
                    ))}
                </div>
            </Popup>

            {/* Fullscreen Slider */}
            {sliderOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-90 z-[9999] flex items-center justify-center p-8"
                >
                    {/* Close Button */}
                    <button
                        className="absolute top-4 right-4 text-white text-3xl cursor-pointer z-[110]"
                        onClick={() => setSliderOpen(false)}
                    >
                        <Svgs.CrossIcon2 />
                    </button>

                    {/* Slider Buttons */}
                    <div
                        className="absolute z-[10001] top-1/2 left-8 transform -translate-y-1/2 cursor-pointer flex items-center justify-center h-10 w-10 bg-white shadow-lg rounded-full"
                        onClick={previous}
                    >
                        <Svgs.ArrowBackIcon />
                    </div>
                    <div
                        className="absolute z-[10001] top-1/2 right-8 transform -translate-y-1/2 cursor-pointer flex items-center justify-center h-10 w-10 bg-white shadow-lg rounded-full"
                        onClick={next}
                    >
                        <Svgs.ArrowNextIcon />
                    </div>

                    {/* Slider */}
                    <Slider {...sliderSettings} ref={sliderRef} className="w-full h-full">
                        {images?.map((image, ind) => (
                            <div
                                key={ind}
                                className="flex justify-center items-center w-full h-[90vh]"
                            >
                                <Image
                                    src={image?.url}
                                    alt=""
                                    className="w-full object-contain rounded-lg h-[90vh]"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </>
    );
};

export default ImageGalleryPopup;
