import { getAdminSingleRestaurantMenuData, getAdminSingleRestaurantMenuPdfData } from "Adapters/APIs/Admin/restaurant";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ContentCard from "./ContentCard";
import { deleteMenuCategory, deleteMenuItem, deletePDFCategory } from "Adapters/APIs/Restaurant/menu";

const useSingleRestaurantMenu = (isAdmin) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { restuarant_id } = useParams();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [activeTab, setActiveTab] = useState('MainMenu')
    const [loader, setLoader] = useState(true)
    const [pdfMenuLoader, setPdfMenuLLoader] = useState(true)
    const [menuData, setMenuData] = useState([])
    const [allMenuData, setAllMenuData] = useState([])
    const [pdfMenudata, setPdfMenuData] = useState({})
    const [menuPopup, setMenuPopup] = useState(false)
    const [addPopup, setAddPopup] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pdfData, setPdfData] = useState("")
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [pDFDeleteLoader, setPDFDeleteLoader] = useState(false)

    // get restaurant menu
    const getRestaurantMenuData = () => {
        let payload = {
            restaurant_id: restuarant_id,
        }
        setLoader(true)
        const success = (res) => {
            setAllMenuData(res?.categories)
            setLoader(false)
        }
        const fail = () => {
            setAllMenuData([])
            setLoader(false)
        }
        dispatch(getAdminSingleRestaurantMenuData(access_token, payload, success, fail))
    }

    // get restaurant menu pdf
    const getRestaurantMenuPDFData = () => {
        let payload = {
            restaurant_id: restuarant_id,
        }
        setPdfMenuLLoader(true)
        const success = (res) => {
            setPdfMenuData(res?.pdf_menu)
            setPdfMenuLLoader(false)
        }
        const fail = () => {
            setPdfMenuLLoader(false)
        }
        dispatch(getAdminSingleRestaurantMenuPdfData(access_token, payload, success, fail))
    }

    // delete menu
    const deleteMainMenu = () => {
        const success = () => {
            onSuccess()
            setDeletePopup(false)
            setLoading(false)
            setAddPopup(false)
        }
        const fail = () => { setLoading(false) }
        setLoading(true)

        deletePopup?.type
            ? dispatch(deleteMenuItem(access_token, deletePopup?.id, success, fail, 'admin'))
            : dispatch(deleteMenuCategory(access_token, deletePopup?.id, success, fail, 'admin'))
    }

    // on success callback
    const onSuccess = () => {
        getRestaurantMenuPDFData()
        getRestaurantMenuData()
    }

    // delete pdf category
    const deletePDF = () => {
        const success = () => {
            setLoader(true)
            onSuccess()
            setPDFDeleteLoader(false)
            setDeleteConfirmation(false)
        }
        const fail = () => {
            setPDFDeleteLoader(false)

        }
        let data = {
            id: pdfMenudata?.id,
            category: activeTab == 'MainMenu' ? 'main' : activeTab == 'BeverageMenu' ? 'beverage' : 'dessert',
            restaurant_id: restuarant_id
        }
        setPDFDeleteLoader(true)
        dispatch(deletePDFCategory(access_token, data, success, fail, 'admin'))
    }


    useEffect(() => {
        setLoader(true)
        setPdfMenuLLoader(true)
        onSuccess()
    }, [])

    useEffect(() => {
        if (pdfMenudata?.url || pdfMenudata?.beverage || pdfMenudata?.dessert) {
            setPdfData(activeTab == 'MainMenu' ? pdfMenudata?.url : activeTab == 'BeverageMenu' ? pdfMenudata?.beverage : pdfMenudata?.dessert)
        } else {
            setPdfData()
        }
    }, [activeTab, pdfMenudata])

    useEffect(() => {
        if (allMenuData?.length > 0) {
            let type = activeTab == 'MainMenu' ? 'main_menu' : activeTab == 'BeverageMenu' ? 'beverage_menu' : 'dessert_menu'
            const filteredData = allMenuData?.filter(itm => itm?.type == type)?.map((itm, ind) => ({
                title: itm?.name,
                content: itm?.items?.map((obj, ind) => <ContentCard
                    data={obj}
                    index={ind}
                    showDelete={isAdmin}
                    heading={obj?.name}
                    onDeleteClick={(id) => setDeletePopup({ id: id, type: true })}
                    description={obj?.description}
                    price={obj?.price}
                    discount={obj?.discount}
                />),
                id: itm?.id,
                name: itm?.name,
                restaurant_id: itm?.restaurant_id,
                status: itm?.status,
                type: itm?.type,
            }))
            setMenuData(filteredData)
        } else {
            setMenuData([])
        }
    }, [allMenuData, activeTab])

    return {
        menuData, loader, navigate, activeTab, setActiveTab, pdfMenuLoader, pdfMenudata, menuPopup, setMenuPopup,
        addPopup, setAddPopup, deletePopup, setDeletePopup, onSuccess, loading, deleteMainMenu, restuarant_id, pdfData,
        deleteConfirmation, setDeleteConfirmation, pDFDeleteLoader, setPDFDeleteLoader, deletePDF
    }
}

export default useSingleRestaurantMenu