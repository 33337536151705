import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useRestaurantTotalRecievableHelper from './helper'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import BookingAnalyticsFilter from 'Pages/Admin/Analytics/Elements/BookingAnalyticsFilter'
import { RESTAURANT_BOOKING_STATUSES } from 'Constants/Data/Statuses'
import Back from 'Components/Elements/Back'
import Pagination from 'Components/Pagination'
import RestaurantTotalRecievableTable from 'Utility/Tables/RestaurantTotalRecievableTable'

const RestaurantTotalRecievable = () => {
    const { paginationData, currentPage, setCurrentPage, filterData, setFilterData, loader, data, addFilter, setAddFilter, handleChangeFilter,
        onClearFilter, onApplyFilter
    } = useRestaurantTotalRecievableHelper()

    return (
        <DashboardLayout active={'totalRecievable'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        title={'Total Recievables'}
                    />
                    <div className='flex gap-2 items-center'>
                        <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                    </div>
                </div>

                <div className="lg:col-span-8 space-y-5">
                    <div className="space-y-3">
                        <div className="relative sm:rounded-lg border border-lightGrey">
                            {loader
                                ? <BookingTableShimer columns={9} />
                                : <>
                                    <RestaurantTotalRecievableTable data={data?.all_bookings} />
                                    {data?.all_bookings?.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={data?.length}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </main>

            <BookingAnalyticsFilter
                bookingStatuses={RESTAURANT_BOOKING_STATUSES}
                open={addFilter}
                close={setAddFilter}
                hideSelection={true}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                activeTab={''}
                filterData={filterData}
                setFilterData={setFilterData}
                dropdownOptions={[]}
                handleChangeFilter={handleChangeFilter}
                isFromRestaurantTotalRecievables
            />
        </DashboardLayout>
    )
}

export default RestaurantTotalRecievable