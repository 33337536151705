import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import React from 'react'
import DownloadCSVPopup from '../Analytics/Elements/DownloadCSVPopup'
import useAdminExportHelper from './helper'

const AdminExport = () => {
    const { onDownloadClick, setOnDownloadClick, downloadLoading, DownloadClick, downloadFilter, setDownloadFilter } = useAdminExportHelper()
    return (
        <DashboardLayout active={'export'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        title={'Export'}
                    />
                    <Button
                        className={'flex gap-2 items-center'}
                        onClick={() => setOnDownloadClick(true)}
                    >
                        <Svgs.DownloadIcon />
                        Download CSV
                    </Button>
                </div>
                <div className="py-6">

                </div>
            </main>

            <DownloadCSVPopup
                open={onDownloadClick}
                close={setOnDownloadClick}
                downloadLoading={downloadLoading}
                onApplyFilter={DownloadClick}
                filterData={downloadFilter}
                setFilterData={setDownloadFilter}
            />
        </DashboardLayout>
    )
}

export default AdminExport
