import { BASE_URL, get_admin_business_bookings_to_pay, get_invoices_data, get_pending_payable_and_recieveable_data, pay_business } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import axios from "axios";
import { toast } from "react-toastify";

// get admin finance data
export const getAdminFinanceInvoicesData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_invoices_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// get pending recievables and payables
export const getAdminPendingRecievablesPayablesData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${payload?.isPendingRestaurantPayables ? "eb/" : ""}${get_pending_payable_and_recieveable_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// get venue bookings to pay
export const getAdminBusinessBookingsData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_admin_business_bookings_to_pay}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail(error)
        return error;
    }
}

// pay business dues
export const payBusinessRemainingDues = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = new FormData();
    formData.append('paid_proof', payload.paid_proof);
    formData.append('paid_amount', payload.paid_amount);
    payload?.reservationIds?.forEach(item => {
        formData.append('reservation_id[]', item);
    });
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${pay_business}`, formData, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}
