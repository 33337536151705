import React from 'react';
import LoginInput from 'Components/Elements/LoginInput';
import PasswordInput from 'Components/Elements/PasswordInput';
import useLogin from './helper';
import Button from 'Components/Elements/Button';
import ConfirmationPopup from 'Components/ConfirmationPopup/ConfirmationPopup';
import Svgs from 'Assets/svgs';
import TermAndSignaturePopup from './TermAndSignaturePopup';
// import ToggleWithText from 'Components/Elements/ToggleWithText';

const Login = () => {
    const {
        errors, userData, loading, onChangeHandler, navigate, onHandleSubmit, recoverPopup, setRecoverPopup, setLoading,
        termsSignaturePopup, setTermsSignaturePopup, termStep, setTermStep, signatureValue, setSignatureValue,
        handleSubmitSigntureData, signatureLoading
    } = useLogin()

    return (
        <section className="flex h-[100dvh] w-full ">

            <div className="grid grid-cols-1 md:grid-cols-2 w-full ">
                <div className="flex justify-center  items-center h-full overflow-auto">
                    <div className="max-w-md w-full m-auto flex flex-col space-y-8 px-3 pb-5">
                        <div className="flex justify-center">
                            <Svgs.AppLogo width={'190'} height={'100'} />
                        </div>
                        <div className='bg-darkGrey h-[28rem] rounded-3xl p-8'>
                            <h2 className="text-3xl font-semibold pt-3 text-center text-white">Sign In</h2>
                            <p className='text-white text-center py-2 text-sm '>Sign In to access your account!</p>
                            <div className='flex flex-col gap-5 mt-4'>
                                <LoginInput
                                    title={'Email Address'}
                                    name={'email'}
                                    value={userData?.email}
                                    error={errors?.email}
                                    onChange={onChangeHandler}
                                    placeholder={'Enter email address'}
                                    required={true}
                                    onEnterSubmit={onHandleSubmit}
                                    inputPadding={"px-4 py-[9px]"}
                                    customColor={'bg-lightGrey'}
                                />
                                <PasswordInput
                                    title={'Password'}
                                    type={'password'}
                                    placeholder={'Enter 8 Digit Password'}
                                    name={'password'}
                                    value={userData?.password}
                                    error={errors?.password}
                                    onChange={onChangeHandler}
                                    onEnterSubmit={onHandleSubmit}
                                    inputPadding={"px-4 py-[9px]"}
                                    customColor={'bg-lightGrey'}
                                />
                                <div className={`flex justify-end items-center mb-4`}>
                                    {/* <ToggleWithText
                                    title2={'Remember me'}
                                    textClass={"text-blueGrey font-normal"}
                                /> */}
                                    <div className="text-secondary font-semibold cursor-pointer text-sm select-none" onClick={() => navigate('/forgot-password')}>Forgot Passwod?</div>
                                </div>
                                <div className='pt-2'>
                                    <Button
                                        text={'Sign In'}
                                        className={'w-full'}
                                        loading={loading}
                                        disabled={loading}
                                        onClick={() => onHandleSubmit()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center gap-2 text-sm">
                            <p className='text-white'>Don't have an account?</p>
                            <span className='text-secondary cursor-pointer' onClick={() => navigate('/role-selection')}>Sign Up</span>
                        </div>
                    </div>
                </div>
                <div
                    className="w-full h-full bg-cover bg-no-repeat bg-center md:block hidden"
                    style={{ backgroundImage: "url('/img/auth/login.png')" }}
                >
                    {/* <img className='h-full w-full' src="/img/auth/loginlayer.png" alt="" /> */}
                </div>
            </div>

            <ConfirmationPopup
                open={recoverPopup}
                close={setRecoverPopup}
                onYesClick={() => {
                    setRecoverPopup(false)
                    setLoading(true)
                    onHandleSubmit({ is_restored: 1 })
                }}
                title={recoverPopup}
                removeHeader={true}
            />

            <TermAndSignaturePopup
                open={termsSignaturePopup}
                close={setTermsSignaturePopup}
                termStep={termStep}
                setTermStep={setTermStep}
                signatureValue={signatureValue}
                setSignatureValue={setSignatureValue}
                handleSubmitSigntureData={handleSubmitSigntureData}
                signatureLoading={signatureLoading}
                errors={errors}
            />
        </section>
    );
}

export default Login;
