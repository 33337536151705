
export const STATUSES = [
    { label: 'Placed', value: "placed", color: "#1D8D70" },
    { label: 'Confirmed', value: "confirmed", color: "#236DE0" },
    { label: 'Billed', value: "billed", color: "#512b83" },
    { label: 'No show', value: "no show", color: "#0a0a0a" },
    { label: 'Cancelled', value: "cancelled", color: "#C00E0E" },
    { label: 'Commission paid', value: "commission paid", color: "#927100" }
]

export const BILL_REQUEST_STATUS = [
    { label: 'Pending', value: "pending" },
    { label: 'Accepted', value: "accepted" },
    { label: 'Completed', value: "completed" }
]

export const RESTAURANT_BOOKING_STATUSES = [
    { label: 'Placed', value: "placed", color: "#1D8D70" },
    { label: 'Confirmed', value: "confirmed", color: "#236DE0" },
    { label: 'Billed', value: "billed", color: "#512b83" },
    { label: 'No show', value: "no show", color: "#0a0a0a" },
    { label: 'Cancelled', value: "cancelled", color: "#C00E0E" }
]

export const DIRECTIONS = [
    { label: 'High to Low', value: "desc" },
    { label: 'Low to High', value: "asc" },
]

export const EARNING_SORT = [
    { label: 'Total Manager Earning', value: "total_manager_earning" },
    { label: 'Total Platfrom Earning', value: "total_platform_earning" },
    { label: 'Total Bookings', value: "total_bookings" }
]

export const TRANSACTION_SORT_FILTER = [
    { label: 'Date', value: "date" },
    { label: 'Bill Value', value: "bill_value" },
    { label: 'Concierges Commission', value: "concierges_commission" },
    { label: 'Platform Commission Value', value: "commission" }
]

export const TRANSACTION_BUSINESS_SORT_FILTER = [
    { label: 'Date', value: "date" },
    { label: 'Bill Amount', value: "bill_amount" },
    { label: 'Commission', value: "commission" },
    { label: 'Business Earning', value: "business_earning" }
]

export const STATISTICS_FILTER = [
    { label: 'Total Bookings', value: "reservations_count" },
    { label: 'Commission Generated', value: "reservations_sum_commission" },
    { label: 'Concierges Commission', value: "reservations_sum_manager_earning" }
]