import React, { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import DummyImage from "../../../../Assets/images/error2.png"
import Svgs from 'Assets/svgs';
import ImageGalleryPopup from 'Pages/Restaurants/Profile/Elements/ImageGalleryPopup';

export default function RestaurantSlider({ data, hideShowAll }) {
    const [openGallery, setOpenGallery] = useState(false);

    return (
        <div className='relative'>
            {(!hideShowAll && data?.length > 4) && (
                <div
                    className="no-propagation flex z-10 absolute rounded-lg px-2.5 cursor-pointer bottom-3 right-1 py-1.5 text-xs gap-1.5 bg-white shadow-lg"
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenGallery(!openGallery);
                    }}
                >
                    <Svgs.GalleryIcon />
                    Show All Photos
                </div>
            )}
            {data?.length > 1 &&
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={10}
                    autoplay={true}
                    breakpoints={{
                        200: {
                            slidesPerView: 3,
                        }
                    }}
                >
                    {data?.slice(1)?.slice(0, 5)?.map((itm, index) => (
                        <SwiperSlide key={index}>
                            <div className='xl:h-20 h-28 w-full'>
                                <img className='h-full w-full object-cover rounded-lg' src={itm?.url} alt={index} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }

            <ImageGalleryPopup
                images={data}
                open={openGallery}
                close={() => { setOpenGallery(false) }}
            />
        </div>
    );
}