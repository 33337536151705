import { getAdminSingleRestaurantApiData, updateRestaurantEBStatusApi, updateRestaurantSubscriptionAndCommission, updateRestaurantToogleStatus } from "Adapters/APIs/Admin/restaurant";
import { postRestaurantBusinessHours } from "Adapters/APIs/restaurants";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

let initialData = {
    admin_percentage: "",
    subscription_charges: "",
    eb_allow: 0
}

const useAdminSingleRestaurant = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { restuarant_id } = useParams();

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const restaurant = state?.adminRestaurants?.singleRestaurant
    const restaurantPromotions = state?.adminRestaurants?.singleRestaurantPromotions

    // states
    const [loader, setLoader] = useState(true)
    const [toogleStatus, setToogleStatus] = useState(false)
    const [confirmToogle, setConfirmToogle] = useState(false)
    const [suspendToogle, setSuspendToogle] = useState(false)
    const [statusLoader, setStatusLoader] = useState(false)
    const [updateSubscriptionLoader, setUpdateSubscriptionLoader] = useState(false)
    const [restaurantSubscriptionData, setRestaurantSubscriptionData] = useState(initialData)
    const [showRatingPopup, setShowRatingPopup] = useState(false)
    const [showBusinessHour, setShowBusinessHour] = useState(false)
    const [businessHourLoader, setBusinessHourLoader] = useState(false)
    const [updateEBStatusLoader, setUpdateEBStatusLoader] = useState(false)

    // get dashboard cards data
    const getSingleRestaurantData = () => {
        let payload = {
            restaurant_id: restuarant_id
        }
        setLoader(true)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleRestaurantApiData(access_token, payload, success, fail))
    }

    // handle change of subscription data
    const handleSubscriptionDataChange = (event) => {
        const { name, value } = event.target
        setRestaurantSubscriptionData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // handleChange Checkbox
    const handleChangeCheckbox = (value) => {
        setRestaurantSubscriptionData((prev) => ({
            ...prev,
            eb_allow: value
        }))
    }


    // update restaurant status
    const changeRestaurantStatus = (type) => {
        setStatusLoader(true)
        let payload = {
            restaurant_id: restaurant?.id
        }
        const success = () => {
            setToogleStatus(false)
            setSuspendToogle(false)
            setStatusLoader(false)
            setLoader(true)
            setConfirmToogle(false)
            getSingleRestaurantData()
        }
        const fail = () => {
            setStatusLoader(false)
        }
        if (type == "suspend") {
            payload = {
                ...payload,
                suspension: true
            }
        }
        dispatch(updateRestaurantToogleStatus(access_token, payload, success, fail))
    }

    // update restaurant status
    const updateRestaurantSubscriptionData = () => {
        let payload = {
            restaurant_id: restaurant?.id,
            subscription_charges: restaurantSubscriptionData?.subscription_charges,
            admin_percentage: restaurantSubscriptionData?.admin_percentage,
            platform_visibility: restaurantSubscriptionData?.eb_allow
        }
        const success = () => {
            setLoader(true)
            setUpdateSubscriptionLoader(false)
            getSingleRestaurantData()
        }
        const fail = () => {
            setUpdateSubscriptionLoader(false)
        }
        let requiredFields = { admin_percentage: restaurantSubscriptionData?.admin_percentage, }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setUpdateSubscriptionLoader(true)
            dispatch(updateRestaurantSubscriptionAndCommission(access_token, payload, success, fail))
        }
    }

    // update restaurant show on eze booking status
    const updateRestaurantShowEzeBookingStatus = (value) => {
        let payload = {
            restaurant_id: restaurant?.id,
            platform_visibility: value
        }
        const success = () => {
            getSingleRestaurantData()
            setUpdateEBStatusLoader(false)
        }
        const fail = () => {
            setUpdateEBStatusLoader(false)
        }
        setUpdateEBStatusLoader(true)
        dispatch(updateRestaurantEBStatusApi(access_token, payload, success, fail))
    }


    // Function to transform business hours
    const transformBusinessHours = (data) => {
        return Object.entries(data)?.length > 0 ? Object.entries(data).map(([day_of_week, { opening_time, closing_time }]) => ({
            day_of_week,
            opening_time,
            closing_time
        })) : [];
    };

    // post business hours
    const postBusinessHours = (data) => {
        const transformedData = transformBusinessHours(data);
        const success = () => {
            getSingleRestaurantData()
            setBusinessHourLoader(false)
        }
        const fail = () => {
            setBusinessHourLoader(false)
        }
        setBusinessHourLoader(true)
        dispatch(postRestaurantBusinessHours(access_token, { restaurant_id: restuarant_id ? restuarant_id : "", hours: transformedData }, success, fail))
    }
    useEffect(() => {
        setRestaurantSubscriptionData({
            admin_percentage: Math.floor(restaurant?.admin_percentage),
            subscription_charges: restaurant?.subscription_charges,
            eb_allow: restaurant?.eb_allow
        })
    }, [restaurant])

    useEffect(() => {
        setToogleStatus(restaurant?.is_active == 1 ? true : false)
    }, [restaurant?.is_active])

    useEffect(() => {
        getSingleRestaurantData()
    }, [])

    return {
        restaurant, navigate, loader, toogleStatus, setToogleStatus, changeRestaurantStatus, confirmToogle, setConfirmToogle, statusLoader,
        restaurantPromotions, handleSubscriptionDataChange, restaurantSubscriptionData, updateRestaurantSubscriptionData, businessHourLoader, postBusinessHours,
        updateSubscriptionLoader, showRatingPopup, setShowRatingPopup, errors, suspendToogle, setSuspendToogle, showBusinessHour, setShowBusinessHour,
        handleChangeCheckbox
    }
}

export default useAdminSingleRestaurant