import Popup from 'Components/Elements/Popup'
import React from 'react'
import useTransactionDetail from './helper'
import CommissionCard from '../Elements/CommissionCard'
import AdminTransactionCommissionShimmer from 'Utility/Shimmers/AdminTransactionCommissionShimmer'

const TransactionDetail = ({ open, close, selectedData, activeTab, successCallback }) => {
    const { loader, data, updateLoader, updateEarningRecievStatus, setData } = useTransactionDetail({ selectedData, activeTab, successCallback, close })
    return (
        <Popup
            open={open}
            close={() => {
                close(false)
                setData()
            }}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={activeTab == "businessPayment" ? "Paid Amount" : 'Commission'}
        >
            <>
                {loader ?
                    <AdminTransactionCommissionShimmer />
                    :
                    <CommissionCard
                        data={data}
                        amount={data?.amount}
                        name={data?.user?.business_name}
                        date={data?.created_at}
                        number={data?.number}
                        proof={data?.proof}
                        activeTab={activeTab}
                        isRecieved={data?.is_received == 1 ? true : false}
                        onRecieveClick={updateEarningRecievStatus}
                        updateLoader={updateLoader}
                    />
                }

            </>
        </Popup>
    )
}

export default TransactionDetail