import { BASE_URL, business_receive_payment_url, get_business_transaction_url } from "Adapters/variables";
import axios from "axios";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { toast } from "react-toastify";
import { getBusinessTransactionAction } from "../../../Redux/Business/Actions";


// get restaurant transaction
export const getRestaurantTransaction = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_business_transaction_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            setLoading && setLoading(false)
            dispatch(getBusinessTransactionAction(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

// recieve commission
export const recieveBusinessCommission = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(data)
        const response = await axios.post(`${BASE_URL}${business_receive_payment_url}${query}`, data, headers);
        if (response?.status == 200) {
            success && success(response);
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        fail && fail();
        return
    }
};