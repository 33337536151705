import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminSliders from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import RestaurantSliderV2 from './Elements/RestaurantSliderV2'
import AddEditSlider from './AddEditSlider'

const AdminSliders = () => {
    const { sliders, pagination_data, loading, currentPage, setCurrentPage, secondLoader, addEditSlider, setAddEditSlider,
        successCallback, editDetail, setEditDetail } = useAdminSliders()
    return (
        <DashboardLayout active={'sliders'}>
            <main className="lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 justify-between px-4 ">
                    <Back
                        title={'Sliders'}
                    />
                    <Button text={'Add Slider'} onClick={() => { setAddEditSlider(true) }} />
                </div>
                <div className="py-6">
                    <div className="lg:col-span-8 space-y-5">
                        {loading ?
                            Array.from({ length: 4 }).map((_, ind) => (
                                <div className="flex flex-col space-y-6">
                                    <div className="flex justify-between gap-4 px-4">
                                        <div className="w-[260px] h-[42px] !rounded-[5px] animate-pulse bg-gray-200"></div>
                                        <div className="w-[108px] h-[42px] !rounded-[5px] animate-pulse bg-gray-200"></div>
                                    </div>
                                    <div className={`pb-6`}>
                                        <RestaurantSliderV2
                                            loading={true}
                                        />
                                    </div>
                                </div>
                            ))
                            :
                            <div className='flex flex-col space-y-4'>
                                {sliders?.length > 0 ? (
                                    <InfiniteScroll
                                        dataLength={sliders?.length ? sliders?.length : 10} //This is important field to render the next data
                                        next={() => {
                                            setCurrentPage(currentPage + 1);
                                        }}
                                        hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                        loader={
                                            secondLoader ? (
                                                <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        }
                                    >
                                        <div className="!m-0 space-y-2 flex flex-col">
                                            {sliders?.map((itm, index) => (
                                                <div
                                                    className={`${index % 2 !== 0 ? "" : ""
                                                        }`}
                                                    key={index}
                                                >
                                                    <div className="flex flex-col space-y-6">
                                                        <div className="flex justify-between gap-4 text-blueGrey px-4">
                                                            <h2 className="font-bold text-xl sm:text-2xl">{itm?.slider?.name}</h2>
                                                            {loading ? (
                                                                <div className="w-[108px] h-[42px] !rounded-[5px] animate-pulse bg-gray-200"></div>
                                                            ) : (
                                                                sliders?.length > 0 && (
                                                                    <Button
                                                                        customThemeBtn="btn-outlined !rounded-[5px] border !border-inputBorder"
                                                                        customPadding="py-1.5 px-4 md:py-2 md:px-7"
                                                                        onClick={() => {
                                                                            setAddEditSlider(true)
                                                                            setEditDetail(itm)
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                )
                                                            )}
                                                        </div>
                                                        <div className={``}>
                                                            <RestaurantSliderV2
                                                                loading={loading}
                                                                restaurants={itm?.restaurants}
                                                                isFromAdmin
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </InfiniteScroll>
                                ) : (
                                    <NotFound />
                                )}
                            </div>
                        }
                    </div>
                </div>
            </main>

            {addEditSlider &&
                <AddEditSlider
                    open={addEditSlider}
                    close={() => {
                        setEditDetail(false)
                        setAddEditSlider(false)
                    }}
                    editData={editDetail}
                    successCallBack={successCallback}
                />
            }
        </DashboardLayout>
    )
}

export default AdminSliders