import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminSpecificConciergeRestaurantAnalytics from './helper'
import SpecificAnalyticsLineChart from '../Elements/SpecificAnalyticsLineChart'
import SpecificConciergeRestaurantFilter from '../Elements/SpecificConciergeRestaurantFilter'
import { DummySpecificConciergeRestaurantData } from 'Constants/Data/DummyGraphData'
import Back from 'Components/Elements/Back'

const AdminSpecificConciergeRestaurantAnalytics = () => {
    const { navigate, filterData, setFilterData, loader, data, addFilter, setAddFilter, handleChangeFilter, onClearFilter, type, onApplyFilter,
        dropdownOptions
    } = useAdminSpecificConciergeRestaurantAnalytics()
    return (
        <DashboardLayout active={'analytics'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        title={`Specific ${type == 'concierge' ? 'Concierge' : 'Business'} Analytics`}
                        loader={!type} />
                    <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                </div>
                <div className="py-6">
                    {loader ?
                        <div className="animate-pulse">
                            <SpecificAnalyticsLineChart data={DummySpecificConciergeRestaurantData} animate />
                        </div>
                        :
                        <SpecificAnalyticsLineChart data={data} />
                    }
                </div>
            </main>

            <SpecificConciergeRestaurantFilter
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                handleChangeFilter={handleChangeFilter}
                type={type}
                dropdownOptions={dropdownOptions}
            />F
        </DashboardLayout>
    )
}

export default AdminSpecificConciergeRestaurantAnalytics