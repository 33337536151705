import { updateRestaurantRejectReason } from "Adapters/APIs/Admin/dashboard";
import { getAdminSingleRestaurantApiData, updateRestaurantEBStatusApi, updateRestaurantSubscriptionAndCommission } from "Adapters/APIs/Admin/restaurant";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

let initialData = {
    admin_percentage: "",
    subscription_charges: "",
    eb_allow: 2
}

const useAdminUnapprovedRestaurant = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { restuarant_id } = useParams();

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const restaurant = state?.adminRestaurants?.singleRestaurant

    // states
    const [loader, setLoader] = useState(true)
    const [restaurantSubscriptionData, setRestaurantSubscriptionData] = useState(initialData)
    const [updateSubscriptionLoader, setUpdateSubscriptionLoader] = useState(false)
    const [rejectPopup, setRejectPopup] = useState(false)
    const [rejectLoader, setRejectLoader] = useState(false)
    const [rejectReason, setRejectReason] = useState('')

    // handle change of subscription data
    const handleSubscriptionDataChange = (event) => {
        const { name, value } = event.target
        setRestaurantSubscriptionData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // get dashboard cards data
    const getSingleRestaurantData = () => {
        let payload = {
            restaurant_id: restuarant_id
        }
        setLoader(true)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleRestaurantApiData(access_token, payload, success, fail))
    }

    // handle reject submission
    const handleRejectSubmission = () => {
        let payload = {
            user_id: restaurant?.user?.id,
            rejection_reason: rejectReason,
            type: 'restaurant'
        }
        const success = () => {
            setLoader(true)
            setRejectLoader(false)
            setRejectPopup(false)
            setRejectReason("")
            getSingleRestaurantData()
        }
        const fail = () => {
            setRejectLoader(false)
        }
        let requiredFields = { rejectReason: rejectReason }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setRejectLoader(true)
            dispatch(updateRestaurantRejectReason(access_token, payload, success, fail))
        }
    }

    // update restaurant status
    const updateRestaurantSubscriptionData = () => {
        let payload = {
            restaurant_id: restaurant?.id,
            subscription_charges: restaurantSubscriptionData?.subscription_charges,
            admin_percentage: restaurantSubscriptionData?.admin_percentage
        }
        const success = () => {
            setUpdateSubscriptionLoader(false)
            navigate("/admin/dashboard")
        }
        const fail = () => {
            setUpdateSubscriptionLoader(false)
        }
        let requiredFields = { admin_percentage: restaurantSubscriptionData?.admin_percentage }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setUpdateSubscriptionLoader(true)
            dispatch(updateRestaurantSubscriptionAndCommission(access_token, payload, success, fail))
        }
    }

    // handleChange Checkbox
    const handleChangeCheckbox = (value) => {
        setRestaurantSubscriptionData((prev) => ({
            ...prev,
            eb_allow: value
        }))
    }

    useEffect(() => {
        setRestaurantSubscriptionData({
            admin_percentage: Math.floor(restaurant?.admin_percentage),
            subscription_charges: restaurant?.subscription_charges,
            eb_allow: restaurant?.eb_allow
        })
    }, [restaurant])

    useEffect(() => {
        getSingleRestaurantData()
    }, [])

    return {
        restaurant, restaurantSubscriptionData, navigate, loader, errors, updateRestaurantSubscriptionData, updateSubscriptionLoader,
        handleSubscriptionDataChange, setRejectPopup, rejectPopup, rejectLoader, rejectReason, setRejectReason, handleRejectSubmission,
        setErrors, handleChangeCheckbox
    }
}

export default useAdminUnapprovedRestaurant