import { getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard";
import { updateOrAddAdminSlider } from "Adapters/APIs/Admin/slider";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


let initialData = {
    name: "",
    restaurants: [],
    is_active: 1,
    platform_visibility: 2
}
const useAddEditSlider = ({ editData, close, successCallBack }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const state = useSelector(state => state)
    const dropdown_restaurants = state?.adminDashboard?.dropdown_restaurants

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState(initialData)
    const [getRestaurantLoader, setGetRestaurantLoader] = useState(true)

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == 'restaurants') {
            setData((prev) => ({
                ...prev,
                [name]: [...data?.restaurants, value]
            }))
        } else {
            setData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }

    // handleChange Checkbox
    const handleChangeCheckbox = (value) => {
        setData((prev) => ({
            ...prev,
            platform_visibility: value
        }))
    }

    // handle remove data from restaurants array
    const handleRemoveRestaurants = (id) => {
        const filteredRestaurants = data?.restaurants?.filter(res => res != id)
        setData((prev) => ({
            ...prev,
            'restaurants': filteredRestaurants
        }))
    }

    // update or add slider data
    const updateOrAddSliderData = () => {
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            handleClose()
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            name: data?.name,
            restaurants: data?.restaurants?.length > 0 ? true : false,
        }
        let result = validation(requiredData);
        setErrors(result);
        if (objectKeyConvertToArray(result)?.length === 0) {
            const formattedRestaurants = data?.restaurants?.reduce((acc, value, index) => {
                acc[`restaurant_ids[${index}]`] = value;
                return acc;
            }, {});
            let payload = {
                id: editData?.slider?.id,
                name: data?.name,
                assign_restaurants: 1,
                is_active: data?.is_active,
                platform_visibility: data?.platform_visibility,
                ...formattedRestaurants,
            }
            console.log(payload, "payload")
            setLoader(true)
            dispatch(updateOrAddAdminSlider(access_token, payload, success, fail))
        }
    }

    // handle close
    const handleClose = () => {
        close && close()
        setData(initialData)
    }

    useEffect(() => {
        if (editData) {
            setData({
                name: editData?.slider?.name,
                is_active: editData?.slider?.is_active,
                restaurants: editData?.restaurants?.map(itm => itm?.id),
                platform_visibility: editData?.slider?.platform_visibility
            })
        }
    }, [editData])

    useEffect(() => {
        dispatch(getDropdownRestaurantAPI(access_token, '', () => { setGetRestaurantLoader(false) }, () => { setGetRestaurantLoader(false) }))
    }, [])

    return {
        data, loader, updateOrAddSliderData, handleChange, setData, errors, handleClose, dropdown_restaurants, getRestaurantLoader,
        handleRemoveRestaurants, handleChangeCheckbox
    }
}

export default useAddEditSlider