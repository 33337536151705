import { getRestaurantTransaction, recieveBusinessCommission } from "Adapters/APIs/Restaurant/Transaction"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const useRestaurantTransactionHelper = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [showProof, setShowProof] = useState(false);
    const [viewBookings, setViewBookings] = useState(false)
    const [viewDetail, setViewDetail] = useState(false)
    const [recieveLoader, setRecieveLoader] = useState(false)

    // redux state
    const state = useSelector(state => state)
    const transaction = state?.business?.transaction
    const pagination_data = state?.business?.transaction_pagination

    // update commission
    const payToRestaurant = () => {
        let payload = {
            transfer_number: viewDetail?.number
        }
        const success = () => {
            setRecieveLoader(false)
            setViewDetail(false)
            setLoading(true)
            setCurrentPage(1)
            setStartDate()
            setEndDate()
            getTrasactionData()
        }
        const fail = () => {
            setRecieveLoader(false)
        }
        setRecieveLoader(true)
        dispatch(recieveBusinessCommission(access_token, payload, success, fail))
    }

    // get trannsaction data
    const getTrasactionData = () => {
        setLoading(true)
        dispatch(getRestaurantTransaction(access_token, {
            page: currentPage,
            end_date: endDate,
            start_date: startDate,
            restaurant_id: user_data?.restaurant?.id
        }, '', '', setLoading))
    }

    useEffect(() => {
        getTrasactionData()
    }, [currentPage, endDate, startDate])

    return {
        navigate, endDate, setEndDate, startDate, setStartDate, currentPage, setCurrentPage, loading, transaction, pagination_data,
        showProof, setShowProof, viewBookings, setViewBookings, viewDetail, setViewDetail, user_data, recieveLoader, payToRestaurant
    }
}

export default useRestaurantTransactionHelper