import React from 'react'
import moment from 'moment'

const BillRequestCard = (props) => {
    const {
        index, name, date_time, status, bill_status, payment_status, onClick, number
    } = props

    return (
        <div className="bg-darkGrey rounded-xl w-full p-4 flex flex-col space-y-[13px] cursor-pointer" onClick={() => { if (onClick) onClick() }} key={index}>
            <div className=''>
                <div className='flex justify-between items-center gap-2'>
                    <h1 className='font-semibold text-white'>{name}</h1>
                    <div className={`${status?.toLowerCase()} text-blueGrey rounded-[10px] px-3 py-1.5 text-xs capitalize`}>{status}</div>
                </div>
                <h1 className='text-blueGrey text-xs'>{moment(date_time)?.format('hh:mm A • DD-MM-YYYY')}</h1>
                <p className='text-secondary text-xs'>ID: #{number}</p>
            </div>
            <hr className='border border-lightGrey' />
            <div className=''>
                <div className='grid grid-cols-2 gap-[11px]'>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-blueGrey text-xs'>Bill Upload</h1>
                        <h1 className={`font-semibold rounded-[5px] text-xs px-1.5 py-0.5 ${bill_status ? "border border-customGreen text-customGreen" : "border border-error text-error"}`}>{bill_status ? 'Yes' : 'No'}</h1>
                    </div>
                    <div className='flex items-center gap-[11px]'>
                        <h1 className='text-blueGrey text-xs'>Payment</h1>
                        <h1 className={`font-semibold rounded-[5px] text-xs px-1.5 py-0.5 ${payment_status ? "border border-customGreen text-customGreen" : "border border-error text-error"}`}>{payment_status ? 'Yes' : 'No'}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillRequestCard