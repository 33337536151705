import React from "react";
import { useForgotPassword } from "./helper";
import LoginInput from "Components/Elements/LoginInput";
import ForgotEmailOTP from "./ForgotEmailOTP";
import OTPScreen from "OnBaording/OTPVerification/OTPScreen";
import Button from "Components/Elements/Button";
import Svgs from "Assets/svgs";
import ResetPassword from "./ResetPassword";
import Back from "Components/Elements/Back";
// import ResetPassword from "./ResetPassword";
// import ForgotEmailOTP from "./ForgotEmailOTP";

const ForgotPassword = () => {
  const {
    handleChange,
    onEmailSubmit,
    onBlurHandler,
    errors,
    loading,
    email, setEmail,
    navigate,
    step,
    setStep,
    loginType, setLoginType,
  } = useForgotPassword();
  return (
    <>
      <section className="w-fulll flex h-[100vh]">
        <div className="flex justify-center items-center py-10 flex-1 overflow-auto">
          <div className="max-w-md w-full flex flex-col space-y-6 px-3">
            <div className="flex justify-center">
              <Svgs.AppLogo width={'190'} height={'100'} />
            </div>
            <div className='bg-darkGrey rounded-3xl p-8'>
              {step === 1
                ? <>
                  <div className="flex flex-col space-y-4 gap-5 relative ">
                    <div className="flex flex-col gap-1">
                      <Back
                        title={"Forgot Password"}
                        fill={'var(--secondary-color)'}
                        titleClassName='font-bold text-[1.5rem] text-white'
                      />
                      <p className="text-white text-sm">
                        Please enter the {loginType === "Mobile" ? "phone number" : "email address"} associated with your account, and we'll send instructions to help you reset your password.
                      </p>
                    </div>
                    <div className={"flex flex-col gap-2"}>
                      <LoginInput
                        name="email"
                        title={'Email Address'}
                        required={true}
                        label="Enter Email"
                        autoComplete={false}
                        value={email}
                        onChange={handleChange}
                        onBlur={() => onBlurHandler({ email: email })}
                        error={errors.email}
                        placeholder={`Enter ${loginType === "Mobile" ? "phone number" : "email"}`}
                        inputPadding={"px-4 py-[9px]"}
                        onEnterSubmit={onEmailSubmit}
                      />
                    </div>
                    <Button
                      text={'Recover Password'}
                      // disabled={!email || errors?.email}
                      loading={loading}
                      onClick={onEmailSubmit}
                      className={'w-full'}
                    />
                  </div>
                </>
                : step === 2
                  ? <OTPScreen step={step} setStep={setStep} isReset email={email} />
                  // <ForgotEmailOTP step={step} setStep={setStep} />
                  : step == 3 &&
                  <ResetPassword step={step} setStep={setStep} />
              }
            </div>
          </div>
        </div>
        <div className="md:block hidden flex-1">
          <div className="w-full h-full flex items-center justify-center bg-[#282828] px-2 overflow-auto">
            <img className='h-[460px] ' src="/img/auth/recoverP.png" alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
