import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Svgs from 'Assets/svgs';
import moment from 'moment';
import { error_message } from 'Constants/Functions/Variables';

const ReactDateTimePicker = ({
    required,
    title,
    name,
    placeholder,
    value,
    min,
    max,
    onChange,
    isClearable,
    error,
    inputPadding,
    readOnly,
    disabled,
    customColor,
    inputClass,
    showBorder,
    parentClass,
    errorClass,
    onEnterSubmit,
    time }) => {

    const [selectDate, setSelectDate] = useState(null);

    // handle change
    const handleDateChange = (value) => {
        if (value != null) {
            const selectedDate = moment(value); // The selected date and time chosen by the user
            const now = moment(); // Current date and time

            // If the time part of the selected date is 12:00 AM (default time), use the current time
            if (
                selectedDate.hour() === 0 &&
                selectedDate.minute() === 0 &&
                selectedDate.isSame(now, 'day')
            ) {
                selectedDate.set({
                    hour: now.hour(),
                    minute: now.minute(),
                    second: now.second(),
                });
            }

            const updatedDate = moment(value).format('YYYY-MM-DD')
            const formattedTime = selectedDate.format('HH:mm'); // Only time

            setSelectDate(selectedDate.toDate());

            const event = {
                target: {
                    name: name,
                    value: updatedDate, // Full date-time string
                    time: formattedTime,      // Only time
                },
            };

            onChange && onChange(event);
        }
    };


    // const handleDateChange = (value) => {
    //     if (value != null) {
    //         setSelectDate(value)

    //         let updatedDate = moment(value).format('YYYY-MM-DD')
    //         let formattedTime = moment(value).format('HH:mm');

    //         let event = {
    //             target: {
    //                 name: name,
    //                 value: updatedDate,
    //                 time: formattedTime
    //             }
    //         }
    //         onChange && onChange(event)
    //     }

    // }

    const HandleKeyPress = (e) => {

        if (e.key.toLowerCase() == "enter") {
            onEnterSubmit();
        }
    };

    const handleClearDate = () => {
        setSelectDate(null);
        let event = {
            target: {
                name: name,
                value: ''
            }
        };
        onChange && onChange(event);
    };

    // Custom styling for disabled and enabled dates
    const dayClassName = (date) => {
        const minDate = min ? moment(min).startOf('day').toDate() : null;  // Ensure minDate starts at the beginning of the day
        const maxDate = max ? moment(max).endOf('day').toDate() : null;    // Ensure maxDate ends at the end of the day

        // Check if the date is before minDate or after maxDate
        if ((minDate && date < minDate) || (maxDate && date > maxDate)) {
            return 'disabled-date';  // Apply disabled class
        }
        return 'enabled-date';  // Allow selection
    };

    // Update selectDate based on value and time
    useEffect(() => {
        if (value) {
            let combinedDateTime = moment(value).startOf('day'); // Initialize with the date part

            if (time) {
                const [hours, minutes] = time.split(':');
                combinedDateTime = combinedDateTime.set({ hour: parseInt(hours, 10), minute: parseInt(minutes, 10) });
            } else {
                combinedDateTime = combinedDateTime.set({ hour: 0, minute: 0 });
            }

            setSelectDate(combinedDateTime.toDate());
        } else {
            setSelectDate(null);
        }
    }, [value, time]);


    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <div className={`${parentClass ? parentClass : ""} relative flex flex-col gap-2 `}>

            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div className="date-picker-container">
                <div className='relative'>
                    <DatePicker
                        toggleCalendarOnIconClick
                        onKeyDown={HandleKeyPress}
                        readOnly={readOnly ? readOnly : false}
                        selected={selectDate}
                        name={name}
                        onChange={handleDateChange}
                        maxDate={max ? new Date(max) : ""}
                        minDate={min ? new Date(min) : ""}
                        onInputError={error}
                        className={`w-full ${error ? "inputError border-[1.5px] border-error" : showBorder ? "border-[1.5px] border-lightGrey" : ""} ${(readOnly || disabled) && 'cursor-not-allowed !bg-gray-800'} ${inputPadding ? inputPadding : "px-4 py-2"} min-h-10 rounded-[10px] w-full text-[0.875rem] ${customColor ? customColor : "bg-darkGrey"} text-base ${inputClass}`}
                        placeholderText={placeholder ? placeholder : "mm/dd/yyyy --/-- --"}
                        dateFormat='MM/dd/yyyy h:mm aa'
                        isClearable={false}
                        dayClassName={dayClassName}
                        autoComplete="off"
                        showTimeSelect
                        filterTime={filterPassedTime}
                    />
                    {isClearable && selectDate && (
                        <button type="button" onClick={handleClearDate} className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <Svgs.CrossIcon2 width={'18'} height={'18'} stroke={'var(--secondary-color)'} />
                        </button>
                    )}
                </div>
                {error && typeof error == "string" ? (
                    <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </p>
                ) : typeof error == "boolean" && error == true ? (
                    <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" /> {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}

ReactDateTimePicker.defaultProps = {
    required: true,
    showBorder: true,
    value: "",
    onEnterSubmit: () => { },
    isClearable: true
};

export default ReactDateTimePicker
