import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminFinance from './helper'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Back from 'Components/Elements/Back'
import PendingRestaurantPayablesTable from 'Utility/Tables/PendingRestaurantPayablesTable'
import Button from 'Components/Elements/Button'
import FilePicker from 'Components/Elements/FilePicker'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import ConciergePayBookingCard from 'Pages/Admin/Concierges/SingleConcierge/Elements/ConciergePayBookingCard'
import CheckBox from 'Components/Elements/Checkbox'
import { currency } from 'Adapters/variables'
import { BOOKING_STATUSES_WITHOUT_ICONS } from 'Constants/Data/BookingStatus'

const AdminPendingRestaurantPayables = ({ fromOtherPage }) => {
    const { financeData, paginationData, loader, currentPage, setCurrentPage, businessPay, setBusinessPay, payBusinessDues, payClicked,
        setPayClicked, paidProof, onProofChangeHandler, businessPayLoader, errors, handleBookingClick, selectedBookings, totalManagerEarnings,
        handleSelectAllBookings, selectedBusinessData, getBookingsLoader, getAdminSingleBusinessDetails } = useAdminFinance()
    return (
        <DashboardLayout active={'Dashboard'}>
            <main className={`${fromOtherPage ? "" : "p-4 lg:ml-72 md:ml-64 h-auto pt-20"} min-h-screen overflow-y-auto`}>
                {!fromOtherPage &&
                    <Back
                        title={'Pending Business Payables'}
                    />
                }
                <div className="!m-0 flex flex-col">
                    <div className={`${fromOtherPage ? "" : "py-6"}`}>
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-table">
                                    {loader
                                        ? <BookingTableShimer columns={6} />
                                        :
                                        <PendingRestaurantPayablesTable
                                            data={financeData}
                                            onPayClick={(data) => {
                                                getAdminSingleBusinessDetails(data?.id)
                                                setBusinessPay(true)
                                            }}
                                        />
                                    }
                                    {financeData?.length > 0
                                        && <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={financeData?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Popup
                open={businessPay}
                close={setBusinessPay}
                size={"sm"}
                heading={'Pay Now'}
            >
                {getBookingsLoader ?
                    <>
                        <div className='mb-4 flex justify-end'>
                            <div className='w-36 h-6 rounded-md bg-gray-400'></div>
                        </div>
                        <div className='flex flex-col space-y-2 mb-5'>
                            {Array.from({ length: 2 }).map((_, ind) => (
                                <div className='w-full h-32 rounded-md bg-gray-400'></div>
                            ))}
                        </div>
                        <div className='w-full h-14 rounded-md bg-gray-400'></div>
                    </>
                    :
                    <>

                        <div className='mb-2 flex justify-end'>
                            <CheckBox
                                name={'concierge_bookings'}
                                required={true}
                                id={'concierge_bookings'}
                                label={'Select All'}
                                checked={selectedBookings?.length == selectedBusinessData?.bookings?.length}
                                onChange={handleSelectAllBookings}
                            />
                        </div>
                        {selectedBusinessData?.bookings?.map((itm, ind) => (
                            <ConciergePayBookingCard
                                name={itm?.guest_name}
                                pax={itm?.total_pax}
                                number={itm?.number}
                                date={itm?.date_time}
                                status={itm?.status}
                                selected={selectedBookings?.find((b) => b?.number === itm?.number)}
                                onClick={() => handleBookingClick(itm)}
                                bookingStatusSteps={BOOKING_STATUSES_WITHOUT_ICONS}
                            />
                        ))}
                        <div className='mt-5'>
                            <Button
                                text={`Pay ${totalManagerEarnings} ${currency}`}
                                className={"w-full"}
                                onClick={() => { setPayClicked(true) }}
                                disabled={selectedBookings?.length == 0}
                            />
                        </div>
                    </>}
            </Popup>

            <Popup
                open={payClicked}
                close={setPayClicked}
                customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                heading={'Proceed to payment'}
            >
                <div className="flex flex-col gap-4">
                    <FilePicker
                        required={true}
                        title="Attach transfer proof"
                        value={paidProof}
                        name={'paidProof'}
                        error={errors?.paidProof}
                        id={'paidProof'}
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"}
                        onChange={onProofChangeHandler}
                    />
                    <LoginInput
                        title={'Payable Amount'}
                        required={false}
                        value={totalManagerEarnings}
                        disabled={true}
                        leftIcon={currency}
                    />
                    <Button className={'mt-2'}
                        text={"Submit"}
                        loading={businessPayLoader}
                        disabled={businessPayLoader}
                        onClick={payBusinessDues}
                    />
                </div>
            </Popup>
        </DashboardLayout>
    )
}

export default AdminPendingRestaurantPayables