import { getDropdownRestaurantAPI } from 'Adapters/APIs/Admin/dashboard'
import { getConciergeBookingAnalytics, getConciergeBookingAnalyticsCSVData } from 'Adapters/APIs/Concierge/Bookings'
import { getTeamMembersData } from 'Adapters/APIs/Concierge/TeamMembers'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

let initialFilterState = {
    dropdownValue: '',
    start_date: "",
    end_date: "",
    status: "",
    filter: "",
    concierge_team_member_id: "",
    date_filter_type: "date"
}

const useBookingAnalytics = () => {
    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // redux states
    const state = useSelector(state => state)
    const booking = state?.bookings?.booking_analytics
    const total_bill_amount = state?.bookings?.total_bill_amount
    const total_manager_earning = state?.bookings?.total_manager_earning
    const total_concierges_recieved = state?.bookings?.total_concierges_recieved
    const restaurants = state?.adminDashboard?.dropdown_restaurants
    const pagination_data = state?.bookings?.ba_pagination
    const team_members = state?.concierge?.team_members_dropdown

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterPopup, setFilterPopup] = useState(false)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setloading] = useState(true)
    const [downloadCSV, setDownloadCSV] = useState(false)
    const [downloadCSVData, setDownloadCSVData] = useState({
        month: "",
        year: new Date().getFullYear().toString()
    })
    const [CSVLoader, setCSVLoader] = useState(false)

    // on apply filter
    const onApplyFilter = () => {
        setFilterPopup(false)
        if (filterUpdate) {
            setloading(true)
            setCurrentPage(1)
            getBookingAnalytics()
        }
    }

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // get Booking Analytics
    const getBookingAnalytics = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10
        }
        if (!noFilter) {
            payload = {
                ...payload,
                page: currentPage,
                end_date: filterData?.end_date,
                start_date: filterData?.start_date,
                restaurant: filterData?.dropdownValue,
                status: filterData?.status,
                filter: filterData?.filter == 'date' ? '' : filterData?.filter,
                concierge_team_member_id: filterData?.concierge_team_member_id ? filterData?.concierge_team_member_id : ""
            }
        }
        setloading(true)
        const success = () => {
            setloading(false)
        }
        const fail = () => {
            setloading(false)
        }
        dispatch(getConciergeBookingAnalytics(access_token, payload, success, fail))
    }

    // get csv data
    const getBookingAnalyticsCSVData = () => {
        let payload = {
            export: "csv",
            concierges: user_data?.id,
            year: downloadCSVData?.year,
            month: downloadCSVData?.month,
        }
        setCSVLoader(true)
        const success = () => {
            setCSVLoader(false)
            setDownloadCSV(false)
            setDownloadCSVData({
                month: "",
                year: new Date().getFullYear().toString()
            })
        }
        const fail = () => {
            setCSVLoader(false)
        }
        dispatch(getConciergeBookingAnalyticsCSVData(access_token, payload, success, fail))
    }

    // on clear filter
    const onClearFilter = () => {
        setFilterPopup(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            setCurrentPage(1)
            setloading(true)
            getBookingAnalytics(true)
            setFilterData(initialFilterState)
        }
    }

    // get team members
    const getTeamMembers = () => {
        const success = () => { }
        const fail = () => { }
        let payload = {
            is_dropdown: true,
            user_id: user_data?.id
        }
        dispatch(getTeamMembersData(access_token, payload, success, fail))
    }

    const TeamMembers = useMemo(() => {
        const members = team_members?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })

        // Filter to ensure only unique values are kept
        const uniqueMembers = members?.length > 0 ? members?.filter((member, index, self) =>
            index === self.findIndex((m) => m.value === member.value)
        ) : [];
        return uniqueMembers;
    }, [team_members])

    useEffect(() => {
        getBookingAnalytics()
    }, [currentPage])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    useEffect(() => {
        dispatch(getDropdownRestaurantAPI(access_token))
        getTeamMembers()
    }, [])

    return {
        setFilterData, filterData, restaurants, startDate, endDate, setStartDate, setEndDate, loading, booking, currentPage, pagination_data,
        setCurrentPage, total_bill_amount, total_manager_earning, filterPopup, setFilterPopup, onApplyFilter, onClearFilter, handleChangeFilter,
        total_concierges_recieved, user_data, TeamMembers, downloadCSV, setDownloadCSV, downloadCSVData, setDownloadCSVData, CSVLoader,
        getBookingAnalyticsCSVData

    }

}

export default useBookingAnalytics