import React, { useEffect, useState } from 'react';
import 'swiper/css';
import DummyImage from "../../../../Assets/images/error2.png";
import Svgs from 'Assets/svgs';
import { convertImgToUrl } from 'Hooks/useObjectKeys';
import Image from 'Components/Elements/Image';

export default function EditRestaurantSlider({ data, onRemove, hideRemove, toggleGalleryPopup }) {
    const [loading, setLoading] = useState(true); // State to manage the loader

    useEffect(() => {
        // Set a timeout for 3 seconds to simulate loading
        const timer = setTimeout(() => {
            setLoading(false); // Hide loader after 3 seconds
        }, 1000);

        return () => clearTimeout(timer); // Cleanup the timeout when component unmounts
    }, []);

    return (
        <div className="overflow-x-auto py-2 pr-4">
            <div className="flex gap-4 w-max relative">
                {loading
                    ? Array.from({ length: 5 }).map((itm, index) => {
                        return <div className="h-[120px] w-[162px] flex-shrink-0 relative cursor-pointer bg-gray-400 rounded-lg" key={index}>
                        </div>
                    })
                    : data?.length > 0
                        ? data?.slice(0, 5)?.map((itm, index) => {
                            const isLastVisible = index === Math.min(4, data.length - 1); // Check if this is the last visible item
                            return (
                                <div className="h-[120px] w-[162px] flex-shrink-0 relative cursor-pointer" key={index}>
                                    <Image
                                        className="h-[120px] w-full object-cover rounded-lg"
                                        src={itm?.url || convertImgToUrl(itm)}
                                        alt={`Image-${index}`}
                                    />
                                    {/* Show All Photos Icon */}
                                    {data?.length > 5 && isLastVisible && (
                                        <div
                                            className="flex absolute rounded-lg px-2.5 cursor-pointer bottom-3 right-3 py-1.5 text-xs gap-1.5 bg-white"
                                            onClick={() => toggleGalleryPopup()}
                                        >
                                            <Svgs.GalleryIcon />
                                            Show All Photos
                                        </div>
                                    )}
                                    {!hideRemove && (
                                        <div
                                            className="absolute -top-2 -right-2 border rounded-full p-2 bg-[#FFE8E1] border-[#FFE8E1]"
                                            onClick={() => {
                                                onRemove && onRemove(itm?.id, index);
                                            }}
                                        >
                                            <Svgs.CrossIcon fill={'#FF5722'} />
                                        </div>
                                    )}
                                </div>
                            );
                        })
                        : (
                            <div className="h-[120px] w-[200px] flex-shrink-0">
                                <img className="h-full w-full object-cover rounded-lg" src={DummyImage} alt="dummy" />
                            </div>
                        )}
            </div>
        </div>
    );
}
