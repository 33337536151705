import React, { useState } from 'react';
import DummyImage from "../../Assets/images/dummtImg.png";
import useCustomLazyLoading from 'Hooks/useCustomLazyLoading';
import ImageShimmer from 'Utility/Shimmers/ImageShimmer';

const Image = ({
    src,
    className,
    alt,
    fallback,
    showDummy,
    customLoaderHeight,
    customLoaderClass,
    loading,
    onClick,
    showImgInNewTab
}) => {
    const imageLoading = useCustomLazyLoading(src);
    const [broken, setBroken] = useState(false);

    const handleImageError = () => {
        setBroken(true);
    };

    const imageElement = broken || !src ? (
        <img
            className={`${className}`}
            src={showDummy ? DummyImage : fallback ? fallback : "/img/logo3.svg"}
            alt="fallback"
        />
    ) : (
        <img
            src={src}
            className={`${className}`}
            alt={alt ? alt : "Hotel Concierge"}
            onError={handleImageError}
        />
    );

    return (
        <div onClick={onClick && onClick}>
            {!imageLoading || loading ? (
                <ImageShimmer
                    customHeight={customLoaderHeight}
                    className={customLoaderClass ? customLoaderClass : className}
                />
            ) : showImgInNewTab ? (
                <a href={src} target="_blank" rel="noopener noreferrer">
                    {imageElement}
                </a>
            ) : (
                imageElement
            )}
        </div>
    );
};

export default Image;










// OLD CODE
// import React, { useState } from 'react';
// import DummyImage from "../../Assets/images/dummtImg.png"
// import useCustomLazyLoading from 'Hooks/useCustomLazyLoading';
// import ImageShimmer from 'Utility/Shimmers/ImageShimmer';

// const Image = ({ src, className, alt, fallback, showDummy, customLoaderHeight, customLoaderClass, loading, onClick }) => {
//     const imageLoading = useCustomLazyLoading(src)
//     const [broken, setBroken] = useState(false);

//     const handleImageError = () => {
//         setBroken(true);
//     };

//     return (
//         <div onClick={onClick && onClick}>
//             {!imageLoading || loading ?
//                 <ImageShimmer customHeight={customLoaderHeight} className={customLoaderClass ? customLoaderClass : className} />
//                 : broken || !src ? (
//                     <img className={`${className}`} src={showDummy ? DummyImage : fallback ? fallback : "/img/logo3.svg"} alt="fallback" />
//                 ) : (
//                     <img src={src} className={`${className}`} alt={alt ? alt : "Hotel Conierge"} onError={handleImageError} />
//                 )}
//         </div>
//     );
// };

// export default Image;
