import React from 'react'
import DashboardLayout from '../../../Components/DashboardLayout'
import useDashboard from './helper';
import Svgs from 'Assets/svgs';
import Button from 'Components/Elements/Button';
import useProfile from '../Profile/helper';
import RestaurantRatingPupup from '../Profile/RatingPopup';
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer';
import DashboardCard from 'Components/ConceirgeCards/DashboardCard';
import Image from 'Components/Elements/Image';
import ReminderManager from './Elements/ReminderManager';
import ImageGalleryPopup from '../Profile/Elements/ImageGalleryPopup';
// import NotFound from 'Utility/NotFound';
// import AddPromotion from '../Promotions/AddPromotion';
// import ViewAllBtn from 'Components/Elements/ViewAllBtn';
// import DummyImage from "../../../Assets/images/error2.png"
// import SingleRestaurantRatingPupup from 'Pages/Admin/Restaurants/SingleRestaurant/SingleRestaurantRatingPupup';
// import PromotonCard from 'Components/PromotionCard';
// import PromotionCardShimmer from 'Utility/Shimmers/PromotionCardShimmer';
// import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards';
// import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer';

const RestaurantDashbaord = () => {
    const {
        dashboardData, promotions, navigate, loader, promotionloading, addPromotion, setAddPromotion, user_data, bookingLoader, bookings,
        openReminder, setOpenReminder, openGallery, toggleImageGalleryPopup
    } = useDashboard()

    const {
        openRatingPopup, setOpenRatingPopup, loading, ratingData, setRatingData, handleSubmit, errors, setErrors, validateWebsiteUrl
    } = useProfile()
    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="pt-4 space-y-5">
                        <h2 className="font-bold text-2xl text-secondary">Dashboard</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                            {loader
                                ? <>
                                    <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 min-w-40'} shimmerStyle={'!p-0 rounded-[20px]'} />
                                    <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 min-w-40'} shimmerStyle={'!p-0 rounded-[20px]'} />
                                    <ConceirgeCardShimmer icon showViewDetail={false} customPadding={'!p-0 min-w-40'} shimmerStyle={'!p-0 rounded-[20px]'} />
                                </>
                                : <>
                                    <DashboardCard
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalEarned width={29} height={28} />}
                                        iconWrapperStyle={'size-11'}
                                        title1={'This month sale'}
                                        amount1={dashboardData?.current_month_earned}
                                        showViewDetail={false} />
                                    <DashboardCard
                                        iconWrapperStyle={'size-11'}
                                        title1={'Total sale since joining'}
                                        amount1={dashboardData?.total_sale}
                                        showViewDetail={false}
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalSales width={35} height={28} />}
                                    />
                                    <DashboardCard
                                        iconWrapperStyle={'size-11'}
                                        title1={'Total bookings since joining'}
                                        amount1={dashboardData?.totalBookings}
                                        showViewDetail={false}
                                        className={'!p-0'}
                                        customPadding={'p-0'}
                                        icon1={<Svgs.TotalBookingJoins className={'size-7'} />}
                                        hideCurrency
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className='py-6'>
                        <h2 className="font-bold text-2xl text-secondary pb-4">My Profile</h2>
                        <div className={`grid xl:grid-cols-2 grid-cols-1 sm:gap-10 gap-4 sm:gap-y-10 gap-y-7 sm:p-7 p-3 justify-between bg-darkGrey rounded-[20px]`}>
                            {/* <div className={`grid ${user_data?.restaurant?.images?.length > 0 ? "lg:grid-cols-2" : ""} grid-cols-1 sm:gap-10 gap-4 sm:gap-y-10 gap-y-7 sm:p-7 p-3 justify-between bg-darkGrey rounded-[20px]`}> */}
                            {/* {user_data?.restaurant?.images?.length > 0 && */}
                            <div className='flex flex-col space-y-4 rounded-3xl'>
                                {/* {user_data?.restaurant?.images?.length > 0 && user_data?.restaurant?.images
                                        ?.filter((_, index) => (index == 0))
                                        ?.map((itm, index) => ( */}
                                {/* <div className={`${user_data?.restaurant?.images?.length == 0 ? "h-full flex-1" : "flex-1 h-[230px]"}`}>
                                                <Image className='h-full w-full object-cover rounded-[20px]' src={itm?.url} alt={index} customLoaderHeight={`${user_data?.restaurant?.images?.length == 0 ? "h-full flex-1 " : "flex-1  h-[450px]"}`} />
                                            </div> */}
                                <div className={`rounded-[20px] bg-lightGrey ${user_data?.restaurant?.images?.length == 0 ? "h-full flex-1" : "flex-1 h-[230px]"}`}>
                                    <Image className='w-full object-cover object-top rounded-[20px] md:h-[450px] h-[230px]' src={user_data?.restaurant?.display_picture ? user_data?.restaurant?.display_picture : user_data?.restaurant?.images[0]?.url} showDummy alt={'img'} customLoaderHeight={`${user_data?.restaurant?.images?.length == 0 ? "h-full flex-1 " : "flex-1  h-[450px]"}`} />
                                </div>
                                {/* ))
                                    } */}
                                {user_data?.restaurant?.images?.length > 1 &&
                                    <div className='grid grid-cols-3 gap-2 items-center relative'>
                                        {user_data?.restaurant?.images?.length > 4 && (
                                            <div
                                                className="flex absolute rounded-lg px-2.5 cursor-pointer bottom-3 right-1 py-1.5 text-xs gap-1.5 bg-white"
                                                onClick={() => toggleImageGalleryPopup()}
                                            >
                                                <Svgs.GalleryIcon />
                                                Show All Photos
                                            </div>
                                        )}
                                        {user_data?.restaurant?.images
                                            ?.filter((_, index) => (index > 0 && index < 4))
                                            ?.map((itm, index) => (
                                                <div className='h-[108px] w-full aspect-square'>
                                                    <Image className='h-[108px] w-full object-cover rounded-[10px]'
                                                        customLoaderClass={'h-[108px] w-full object-cover rounded-[10px]'}
                                                        src={itm?.url} alt={index}
                                                        customLoaderHeight={'h-[108px]'} />
                                                </div>
                                            ))}
                                    </div>
                                }
                            </div>
                            {/* } */}
                            <div className='rounded-3xl flex flex-col space-y-5 pb-8'>
                                <h2 className='text-white font-semibold sm:text-2xl'>{user_data?.restaurant?.name}</h2>
                                <div className="flex gap-1 items-center text-white text-sm">
                                    <span><Svgs.LocationIcon width={20} height={20} stroke={'var(--secondary-color)'} /></span>
                                    <span className="">{user_data?.address}</span>
                                </div>
                                <div className='flex xs:flex-row flex-col justify-between gap-4 sm:items-center'>
                                    <div className='flex flex-1 gap-4 items-center'>
                                        <div className="bg-lightGrey rounded-full flex flex-col gap-3 items-center justify-between py-[10px] xl:px-4 px-3 cursor-pointer" onClick={() => { setOpenRatingPopup('rating') }}>
                                            <div className="flex gap-2 items-center">
                                                <Svgs.StarIcon className={'size-5'} />
                                                <p className='text-xs font-bold text-white'>Add Rating</p>
                                            </div>
                                        </div>
                                        <div className="bg-lightGrey rounded-full flex flex-col gap-3 items-center justify-between py-[10px] xl:px-4 px-3 cursor-pointer" onClick={() => { navigate(`/restaurant/review`) }}>
                                            <div className="flex gap-2 items-center">
                                                {user_data?.restaurant?.average_rating >= 0 && <Svgs.StarIcon className={'size-5'} />}
                                                <p className='text-xs font-bold text-white'>{user_data?.restaurant?.average_rating} ({user_data?.restaurant?.total_rating ? user_data?.restaurant?.total_rating : 0} Reviews)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        customPadding={'rounded-[10px] px-4 py-2 gap-1.5'}
                                        onClick={() => { navigate(`/restaurant/menu`) }}
                                    >
                                        <Svgs.MenuIcon className={'size-5'} fill={'var(--primary-color)'} />
                                        <p>Menu</p>
                                    </Button>
                                </div>
                                <hr className='border-lightGrey' />
                                <div className='grid xs:grid-cols-2 grid-cols-1 gap-4'>
                                    <div className='rounded-[20px] min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-lightGrey'>
                                        <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                            <Svgs.ColoredPhoneIcon stroke={'var(--secondary-color)'} />
                                        </div>
                                        <p className='text-white font-semibold break-words break-all px-4'>{user_data?.restaurant?.phone_number && user_data?.restaurant?.phone_number !== 'null' ? `+${user_data?.restaurant?.phone_code} ${user_data?.restaurant?.phone_number}` : user_data?.restaurant?.mobile_number && user_data?.restaurant?.mobile_number !== 'null' ? `+${user_data?.restaurant?.mobile_code} ${user_data?.restaurant?.mobile_number}` : "----"}</p>
                                    </div>
                                    <div className='rounded-[20px] min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-lightGrey'>
                                        <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                            <Svgs.Mail stroke={'var(--secondary-color)'} />
                                        </div>
                                        <p className='text-white font-semibold break-words break-all px-4'>{user_data?.restaurant?.email}</p>
                                    </div>
                                    <div className='rounded-[20px] min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-lightGrey'>
                                        <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                            <Svgs.Web fill={'var(--secondary-color)'} />
                                        </div>
                                        <p className='text-white font-semibold break-words break-all px-4'>{user_data?.restaurant?.website_link ? user_data?.restaurant?.website_link : "No URL"}</p>
                                    </div>
                                    <div className='rounded-[20px] min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-lightGrey'>
                                        <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                            <Svgs.Insta width={20} height={20} />
                                        </div>
                                        <p className='text-white font-semibold break-words break-all px-4'>{user_data?.restaurant?.instagram_link ? user_data?.restaurant?.instagram_link : "No URL"}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col xl:col-span-2">
                                <h3 className='text-white text-base font-medium'>Description:</h3>
                                <p className='text-blueGrey'>{user_data?.restaurant?.description ? user_data?.restaurant?.description : "-----"}</p>
                            </div>
                        </div>
                    </div>
                    {bookingLoader ?
                        <div className='rounded-md h-44 bg-gray-500 animate-pulse'></div>
                        :
                        bookings?.length > 0 && <div className='py-6 flex justify-between flex-col-reverse sm:flex-row  items-center gap-5 bg-[#e8e5c5] rounded-md p-8 shake-card'>
                            <div className='flex flex-col space-y-2 text-center sm:text-left'>
                                <h2 className='text-3xl font-bold'>New Booking Alert</h2>
                                <p>Please try to confirm it as soon as possibble.</p>
                                <div className='pt-4 flex gap-2 items-center'>
                                    <Button
                                        className={'w-fit'}
                                        title={"View Bookings"}
                                        customThemeBtn={"bg-black border border-black text-white rounded-md"}
                                        onClick={() => { navigate("/restaurant/booking") }}
                                    />
                                    <Button
                                        className={'w-fit'}
                                        title={"Reminder"}
                                        customThemeBtn={"bg-transparent border border-black text-black font-semibold rounded-md"}
                                        onClick={() => { setOpenReminder(!openReminder) }}
                                    />
                                </div>
                            </div>
                            <div className='h-[100px]'>
                                <img className='h-full w-full object-cover' src={"/img/Calendar.png"} alt="dummy" />
                            </div>
                        </div>
                    }
                    {/* <div className="py-6">
                        <div className="flex items-center justify-between">
                            <h2 className="font-bold text-2xl text-white">Promotions</h2>
                            <div className="flex gap-3 justify-end">
                                <ViewAllBtn
                                    onClick={() => navigate('/restaurant/promotion')}
                                />
                                <Button
                                    customPadding={'!p-2 gap-2'}
                                    onClick={() => { setAddPromotion(true) }}
                                    title={'Add Promotion'}
                                />
                            </div>
                        </div>
                        <div className='mt-2'>
                            {promotionloading
                                ?
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                                    {[0, 1, 2, 3, 4, 5, 6, 7]?.map(index => (
                                        <PromotionCardShimmer key={index} />
                                    ))}
                                </div>
                                : promotions?.length == 0
                                    ? <NotFound />
                                    : <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-2">
                                        {promotions?.map(itm => {
                                            return <PromotonCard
                                                key={itm?.id}
                                                url={itm?.url}
                                                start_date={itm?.start_date}
                                                end_date={itm?.end_date}
                                                discount={itm?.discount}
                                            />
                                        })}
                                    </div>
                            }
                        </div>
                    </div> */}
                </main>
                {/* <AddPromotion
                    open={addPromotion}
                    close={setAddPromotion}
                /> */}

                {ratingData &&
                    <RestaurantRatingPupup
                        loading={loading}
                        handleSubmit={handleSubmit}
                        open={openRatingPopup}
                        ratingData={ratingData}
                        close={() => {
                            setOpenRatingPopup(false)
                            setRatingData([
                                {
                                    type: "tripadvisor",
                                    rating: "",
                                    link: ""
                                }, {
                                    type: "google",
                                    rating: "",
                                    link: ""
                                }
                            ])
                        }}
                        setRatingData={setRatingData}
                        errors={errors}
                        setErrors={setErrors}
                        validateWebsiteUrl={validateWebsiteUrl}
                    />
                }

                <ReminderManager
                    showPopup={openReminder}
                    setShowPopup={setOpenReminder}
                />

                <ImageGalleryPopup
                    images={user_data?.restaurant?.display_picture ? [{ url: user_data?.restaurant?.display_picture }, ...user_data?.restaurant?.images] : user_data?.restaurant?.images}
                    name={user_data?.restaurant?.name}
                    open={openGallery}
                    close={toggleImageGalleryPopup}
                />
            </DashboardLayout>
        </>
    )
}

export default RestaurantDashbaord