import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import DateInput from 'Components/Elements/DateInput'
import Dropdown from 'Components/Elements/Dropdown'
import Popup from 'Components/Elements/Popup'
import RadioBtn from 'Components/Elements/RadioBtn'
import { BookingAnalyticsTimePeriod } from 'Constants/Data/Categories'
import { STATUSES } from 'Constants/Data/Statuses'
import React, { useEffect, useState } from 'react'

const BookingAnalyticsFilter = ({ open, close, onClearFilter, hideSelection, onApplyFilter, activeTab, setActiveTab, filterData, setFilterData,
    handleChangeFilter, dropdownOptions, bookingStatuses, teamMembers, showTeamMemberDropdown, isAdmin, isFromRestaurantTotalRecievables
}) => {
    let BookingStatusArray = bookingStatuses ? bookingStatuses : STATUSES
    // states
    const [startDate, setStartDate] = useState(filterData?.start_date)
    const [endDate, setEndDate] = useState(filterData?.end_date)

    useEffect(() => {
        if (startDate) {
            setEndDate()
            setFilterData((prev) => ({
                ...prev,
                start_date: startDate,
                end_date: ''
            }))
        }
    }, [startDate])

    useEffect(() => {
        setFilterData((prev) => ({
            ...prev,
            end_date: endDate
        }))
    }, [endDate])

    // handle on clear filters
    const handleOnClearFilters = () => {
        onClearFilter && onClearFilter()
        setStartDate()
        setEndDate()
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[45vw] lg:w-[40vw]'}
            heading={'Filter by'}
        >
            <div className='flex flex-col space-y-4'>
                {/* Hide Selection in Conierge module */}
                {!hideSelection &&
                    <div className="flex items-center gap-4">
                        <div className={`border ${activeTab == 'concierges' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setActiveTab && setActiveTab('concierges') }}>
                            <RadioBtn name={'activeTab'} text={"Concierges"} checked={activeTab == 'concierges'} id={'concierges'} />
                        </div>
                        {isAdmin &&
                            <div className={`border ${activeTab == 'restaurants' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setActiveTab && setActiveTab('restaurants') }}>
                                <RadioBtn name={'activeTab'} text={"Restaurant"} checked={activeTab == 'restaurants'} id={'restaurants'} />
                            </div>
                        }
                        <div className={`border ${activeTab == 'businesses' && 'border-secondary'} text-blueGrey rounded-lg px-4 py-3 w-full cursor-pointer`} onClick={() => { setActiveTab && setActiveTab('businesses') }}>
                            <RadioBtn name={'activeTab'} text={"Business"} checked={activeTab == 'businesses'} id={'businesses'} />
                        </div>
                    </div>
                }
                {!isFromRestaurantTotalRecievables &&
                    <>
                        {activeTab &&
                            <div>
                                <Dropdown
                                    title={activeTab == "concierges" ? 'Select Concierge' : 'Select Business'}
                                    placeholder={activeTab == "concierges" ? 'Select Concierge' : 'Select Business'}
                                    name={'dropdownValue'}
                                    onChange={handleChangeFilter}
                                    value={filterData?.dropdownValue}
                                    inputClass={"min-w-[180px]"}
                                    option={[
                                        {
                                            label: `All ${activeTab == "concierges" ? 'Concierges' : "Businesses"}`, value: ""
                                        },
                                        ...dropdownOptions?.map(itm => {
                                            return {
                                                value: itm?.id,
                                                label: itm?.first_name ? itm?.first_name : itm?.name
                                            }
                                        })]}
                                />
                            </div>
                        }
                        {showTeamMemberDropdown &&
                            <div className='flex-1'>
                                <Dropdown
                                    title={'Select Member'}
                                    placeholder={'Select Team Member'}
                                    required={false}
                                    name="concierge_team_member_id"
                                    onChange={handleChangeFilter}
                                    value={filterData?.concierge_team_member_id}
                                    option={teamMembers}
                                />
                            </div>}
                        <p className='text-blueGrey text-sm'>By Status</p>
                        <div className='flex gap-2 flex-wrap'>
                            {BookingStatusArray?.map(itm => (
                                <span
                                    key={itm?.value}
                                    className={`${itm?.value?.split(' ')[0]?.toLowerCase()} px-3.5 py-1.5 w-fit rounded-lg text-sm font-normal flex justify-center items-center gap-2 cursor-pointer`}
                                    onClick={() => {
                                        setFilterData((prev) => ({
                                            ...prev,
                                            status: itm?.value
                                        }))
                                    }}
                                >
                                    {itm?.value == filterData?.status &&
                                        <Svgs.Tick stroke={'white'} />}
                                    {itm?.label}
                                </span>
                            ))}
                        </div>
                    </>}
                <div>
                    <Dropdown
                        title={'Select date filter type'}
                        placeholder={'Select date filter type'}
                        name={'date_filter_type'}
                        onChange={handleChangeFilter}
                        value={filterData?.date_filter_type}
                        inputClass={"min-w-[180px]"}
                        option={[
                            { label: `Created At`, value: "created_at" },
                            { label: `Confirmed At`, value: "confirmed_at" },
                            { label: `Booking Date`, value: "date" },
                        ]}
                    />
                </div>
                <div>
                    <Dropdown
                        title={'Select Time period'}
                        placeholder={'Select Time period'}
                        name={'filter'}
                        option={BookingAnalyticsTimePeriod}
                        onChange={handleChangeFilter}
                        value={filterData?.filter}
                    />
                </div>
                {filterData?.filter == 'date' &&
                    <div className='flex gap-2 items-center justify-between'>
                        <DateInput
                            title={'Select Start Date'}
                            name={'startDate'}
                            value={startDate}
                            required={false}
                            onChange={(e) => { setStartDate(e.target.value) }}
                            placeholder={'Select Start Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"flex-1"}
                        />
                        <DateInput
                            title={'Select End Date'}
                            name={'endDate'}
                            value={endDate}
                            required={false}
                            onChange={(e) => { setEndDate(e.target.value) }}
                            placeholder={'Select End Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"flex-1"}
                            min={startDate}
                        />
                    </div>
                }
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} />
                    <Button text={'Apply'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default BookingAnalyticsFilter