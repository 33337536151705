import { getConciergeRestaurantStatsAPI, getDropdownConciergesAPI, getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

let initialFilterState = {
    dropdownValue: "",
    start_date: "",
    end_date: "",
    direction: "desc",
    filter: ""
}
const useAdminStatisticsDetail = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const prevFilterDataRef = useRef(initialFilterState);

    const state = useSelector(state => state)
    const stats = state?.adminDashboard?.concierge_restaurant_stats
    const dropdown_concierges = state?.adminDashboard?.dropdown_concierges
    const dropdown_restaurants = state?.adminDashboard?.dropdown_restaurants

    // states
    const [loader, setLoader] = useState(true)
    const [type, setType] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            dashboardCardData(true)
            setFilterData(initialFilterState)
        }
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            dashboardCardData()
        }
    }

    // get dashboard cards data
    const dashboardCardData = (noFilter) => {
        let payload = {
            type: type,
            page: currentPage,
            per_page: 10,
        }
        if (!noFilter) {
            let dropdownDataValue
            if (type == "concierge") {
                dropdownDataValue = {
                    user_id: filterData?.dropdownValue
                }
            } else {
                dropdownDataValue = {
                    restaurant_id: filterData?.dropdownValue
                }
            }
            payload = {
                ...payload,
                ...filterData,
                ...dropdownDataValue
            }
            delete payload.dropdownValue
        }
        setLoader(true)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getConciergeRestaurantStatsAPI(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoader(true)
        if (type) {
            dashboardCardData()
        }
    }, [type, currentPage])

    useEffect(() => {
        // get dropdown data for concierges and restaurants
        dispatch(getDropdownConciergesAPI())
        dispatch(getDropdownRestaurantAPI())
    }, [])

    useEffect(() => {
        const url = location.pathname; // Get the current pathname
        if (url.endsWith('concierge-statistics')) {
            setType('concierge');
        } else {
            setType('restaurant');
        }
    }, [location]);

    useEffect(() => {
        const prevFilterData = prevFilterDataRef.current;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current state for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    useEffect(() => {
        if (type) {
            if (type == 'concierge') {
                setDropdownOptions(dropdown_concierges)
            } else {
                setDropdownOptions(dropdown_restaurants)
            }
        }
    }, [type, dropdown_concierges, dropdown_restaurants])

    return {
        loader, stats, dropdownOptions, type, currentPage, setCurrentPage, navigate, addFilter, setAddFilter, filterData, setFilterData,
        handleChangeFilter, onClearFilter, onApplyFilter
    }
}

export default useAdminStatisticsDetail