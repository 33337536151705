import { GET_ALL_BUSINESS, GET_TRANSACTION } from "./ActionType";

export const getAllBusinessData = (data) => ({
    type: GET_ALL_BUSINESS,
    payload: data
});

export const getBusinessTransactionAction = (data) => {
    return {
        type: GET_TRANSACTION,
        payload: data
    }
};