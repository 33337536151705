import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import ChatCardShimmer from 'Utility/Shimmers/ChatCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import useRestaurantBillRequestHelper from './helper'
import BillRequestCard from './Elements/BillRequestCard'
import Svgs from 'Assets/svgs'
import BillRequestFilter from './Elements/BillRequestFilter'


const RestaurantBillRequest = () => {
    const { billRequest, pagination, loading, currentPage, setCurrentPage, addFilter, setAddFilter, secondLoader, onApplyFilter,
        onClearFilter, filterData, setFilterData, handleChangeFilter, navigate
    } = useRestaurantBillRequestHelper()
    console.log(billRequest, "billRequest")
    return (
        <>
            <DashboardLayout active={'billRequest'}>
                <main className=" lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="px-4 space-y-4">
                        <div className="flex justify-between items-center">
                            <Back title={'Bill Request'} />
                            <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                        </div>
                    </div>
                    <div className="pb-6">
                        <div className="relative border-t mx-4 py-4 border-lightGrey">
                            {loading
                                ? <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-5 pt-6">
                                    {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                        return <div className='rounded-xl bg-gray-400 animate-pulse h-[126px]'></div>
                                    })}
                                </div>
                                :
                                billRequest?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={billRequest?.length ? billRequest?.length : 10}
                                        next={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}
                                        hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                        loader={secondLoader
                                            && <div className="flex justify-center items-center my-4">
                                                <PreloaderSm />
                                            </div>
                                        }
                                    >
                                        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-5 pt-6">
                                            {billRequest?.map(itm => {
                                                return <BillRequestCard
                                                    name={itm?.reservation?.guest_name}
                                                    number={itm?.reservation?.number}
                                                    date_time={itm?.created_at}
                                                    status={itm?.status}
                                                    bill_status={itm?.reservation?.end_user_transaction?.proof ? 1 : 0}
                                                    payment_status={itm?.reservation?.end_user_transaction?.status == 'paid' ? 1 : 0}
                                                    onClick={() => { navigate(`/restaurant/booking?reservation=${itm?.reservation?.number}`) }}
                                                />
                                            })}
                                        </div>
                                    </InfiniteScroll>
                                    : <NotFound />
                            }
                        </div>
                    </div>
                </main>
            </DashboardLayout>


            <BillRequestFilter
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                handleChangeFilter={handleChangeFilter}
            />
        </>
    )
}
export default RestaurantBillRequest