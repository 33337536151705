import StatusSlider from 'Components/Elements/StatusSlider'
import { BOOKING_STATUSES_WITH_STATUS_PAID } from 'Constants/Data/BookingStatus'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const BookingTable = ({ data, setErrors, setBookingForm, setBookingDetail, bookingDetail, bookingStatusSteps }) => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const defaultRoute = getDataFromLocalStorage('defaultRoute')

    const handleActiveStatus = (status) => {
        switch (status) {
            case 'Placed':
                return 1
            case 'Confirmed':
                return 2
            case 'Billed':
                return 3
            case 'Commission paid':
                return 4
            default:
                return 1
        }
    }
    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white border-b border-table bg-primary capitalize">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Guest Name
                            </th>
                            {defaultRoute == '/concierge/dashboard' &&
                                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                    Business Name
                                </th>
                            }
                            <th scope="col" className="px-6 py-3">
                                Date
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Total Guest
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((itm, index) => {
                            const activeStatus = itm?.end_user_transaction?.status?.toLowerCase() == 'paid' ? 4 : handleActiveStatus(itm?.status);
                            const blinkDelay = index * 0.2;
                            const isPlaced = itm?.status === 'Placed';

                            return (
                                <tr
                                    key={index}
                                    className={`text-blueGrey cursor-pointer text-blueGrey border-b border-table ${isPlaced ? 'shake-blink' : ''} ${itm?.end_user?.id ? "bg-eb" : "bg-primary"}`}
                                    style={isPlaced ? { animationDelay: `${blinkDelay}s` } : {}}
                                    onClick={() => {
                                        setErrors && setErrors();
                                        setBookingForm && setBookingForm(itm);
                                        setBookingDetail && setBookingDetail(!bookingDetail);
                                    }}
                                >
                                    <td className="px-6 py-4">
                                        {itm?.number}
                                    </td>
                                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                                        {itm?.guest_name ? itm?.guest_name : `${itm?.end_user?.first_name ? itm?.end_user?.first_name : ""} ${itm?.end_user?.last_name ? itm?.end_user?.last_name : ""}`}
                                    </th>
                                    {defaultRoute == '/concierge/dashboard' &&
                                        <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                            {itm?.restaurant?.name}
                                        </th>
                                    }
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {moment(itm?.date_time)?.format('DD-MM-YYYY • hh:mm A')}
                                    </td>
                                    <td className="px-6 py-4">
                                        <span className="bg-lightGrey p-1 h-6 w-fit min-w-6 rounded-md text-sm font-normal flex justify-center items-center">
                                            {itm?.total_pax}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 min-w-[250px]">
                                        {itm?.status == 'No show' ?
                                            <div className='flex justify-between items-center gap-2 w-fit border-2 border-error px-3 py-2 rounded-lg'>
                                                <p className='text-xs text-error font-semibold'>No Show</p>
                                            </div> : itm?.status == 'Cancelled' ?
                                                <div className='flex justify-between items-center gap-2 w-fit border-2 border-error px-3 py-2 rounded-lg'>
                                                    <p className='text-xs text-error font-semibold'>Cancelled</p>
                                                </div> :
                                                <StatusSlider
                                                    activeStep={activeStatus}
                                                    customNonActiveColor={'bg-lightGrey'}
                                                    parentClass={"pr-8"}
                                                    steps={itm?.end_user_transaction?.status?.toLowerCase() == 'paid' ?
                                                        BOOKING_STATUSES_WITH_STATUS_PAID
                                                        : bookingStatusSteps ? bookingStatusSteps :
                                                            [{ name: 'Placed', value: 1 },
                                                            { name: 'Confirmed', value: 2 },
                                                            { name: 'Billed', value: 3 },
                                                            { name: 'Commission Paid', value: 4 }
                                                            ]} />
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>
                {(data?.length == 0 || !data) &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default BookingTable