import React from 'react'
import DateRangePicker from 'Components/Elements/ReactDateRange';
import DashboardLayout from 'Components/DashboardLayout';
import Back from 'Components/Elements/Back';
import useRestaurantTransactionHelper from './helper';
import PaymentCardShimmer from 'Utility/Shimmers/PaymentCardShimmer';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import NotFound from 'Utility/NotFound';
import PreloaderSm from 'Components/Elements/PreloaderSm';
import Popup from 'Components/Elements/Popup';
import Image from 'Components/Elements/Image';
import ConciergePayBookingCard from 'Pages/Admin/Concierges/SingleConcierge/Elements/ConciergePayBookingCard';
import { currency } from 'Adapters/variables';
import Button from 'Components/Elements/Button';
import TransactionCard from 'Pages/Concierges/Transaction/Elements/TransactionCard';

const BusinessTransaction = ({ active }) => {
    const { navigate, endDate, setEndDate, startDate, setStartDate, currentPage, setCurrentPage, loading, transaction, pagination_data,
        showProof, setShowProof, viewBookings, setViewBookings, viewDetail, setViewDetail, user_data, recieveLoader, payToRestaurant
    } = useRestaurantTransactionHelper()

    return (
        <>
            <DashboardLayout active={active ? active : 'transaction'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="flex justify-between items-center pt-4 space-y-5">
                        <Back title={"Transactions"} />
                        <DateRangePicker
                            startDate={startDate}
                            className="!max-w-none"
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            maxDate={new Date()}
                        />
                    </div>
                    {loading
                        ? <div className={`gap-4 grid grid-cols-1 sm:grid-cols-2 py-4`}>
                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                return <PaymentCardShimmer />
                            })}
                        </div>
                        : transaction?.length > 0 ?
                            <InfiniteScroll
                                dataLength={transaction?.length ? transaction?.length : 10} //This is important field to render the next data
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }}
                                className='py-4'
                                hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                loader={pagination_data?.current_page !== pagination_data?.total_pages
                                    && <div className="flex justify-center items-center my-4">
                                        <PreloaderSm />
                                    </div>
                                }
                            >
                                <div className={`gap-4 grid grid-cols-1 md:grid-cols-2`}>
                                    {transaction?.map(itm => {
                                        return (
                                            <TransactionCard
                                                date={moment(itm?.created_at)?.format('DD MMM, YYYY')}
                                                amount={itm?.amount}
                                                image={itm?.proof}
                                                status={itm?.is_received}
                                                setShowProof={setShowProof}
                                                setViewBookings={() => {
                                                    setViewBookings(itm?.reservations)
                                                }}
                                                onDetailClick={() => {
                                                    setViewDetail(itm)
                                                }}
                                                number={itm?.number}
                                                onCashIconClick={() => {
                                                    window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/payable-reciept?number=${itm?.number}`, '_blank')
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                            </InfiniteScroll>
                            : <NotFound />
                    }
                </main>
            </DashboardLayout>

            {/* Proof Popup */}
            <Popup
                open={showProof}
                close={setShowProof}
                heading={"Payment Proof"}
            >
                <div className="flex max-h-52">
                    <Image
                        src={showProof}
                        alt={'Payment Proof'}
                        className={'rounded-xl'}
                    />
                </div>
            </Popup>

            {/* Booking Details */}
            <Popup
                open={viewBookings?.length > 0}
                close={() => {
                    setViewBookings(false)
                }}
                heading={"Bookings"}
            >
                {viewBookings?.length > 0 && viewBookings?.map((itm, ind) => (
                    <ConciergePayBookingCard
                        name={itm?.guest_name}
                        pax={itm?.total_pax}
                        onClick={() => {
                            navigate(`/restaurant/booking/?reservation=${itm?.number}`)
                        }}
                        number={itm?.number}
                        // bookingStatusSteps={BOOKING_STATUSES_WITHOUT_COMMISSION_PAID}
                        bookingStatusSteps={[{ name: 'Placed', value: 1 },
                        { name: 'Confirmed', value: 2 },
                        { name: 'Billed', value: 3 }
                        ]}
                        date={itm?.date_time}
                        hideStatus={false}
                        status={itm?.status}
                    />
                ))}
            </Popup>

            <Popup
                open={viewDetail}
                close={setViewDetail}
                heading={<div className='flex flex-col space-y-2'>
                    <h1 className='text-white'>Payment</h1>
                    <p className='text-blueGrey text-sm !font-normal'>Hi {user_data?.restaurant?.name ? user_data?.restaurant?.name : ""}, {viewDetail?.amount} {currency} recieved from platform</p>
                </div>}
            >
                <div className='flex flex-col space-y-2'>
                    <Image className="w-full h-52 object-cover border border-darkGrey rounded-lg" src={viewDetail?.proof} showDummy={true} />
                    <div className={`flex justify-between items-center gap-4 pt-2`}>
                        <Button
                            customThemeBtn={'view-edit-btn'}
                            text={'Cancel'}
                            className={'w-full'}
                            onClick={() => {
                                setViewDetail(false)
                            }}
                        />
                        <Button
                            text={'Recieve'}
                            className={'w-full'}
                            loading={recieveLoader}
                            disabled={recieveLoader}
                            onClick={payToRestaurant}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default BusinessTransaction