import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminConciergeRestaurantAnalytics from './helper'
import AmountCard from '../Elements/AmountCard'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer'
import ConciergeRestaurantAnalytics from 'Utility/Tables/AdminAnalyticsTable/ConciergeRestaurantAnalytics'
import ConciergeRestaurantAnalyticsFilter from '../Elements/ConciergeRestaurantAnalyticsFilter'
import Back from 'Components/Elements/Back'

const AdminConciergeRestaurantAnalytics = () => {
    const { navigate, activeTab, setActiveTab, paginationData, currentPage, setCurrentPage, filterData, setFilterData, loader, data,
        addFilter, setAddFilter, handleChangeFilter, onClearFilter, type, onApplyFilter
    } = useAdminConciergeRestaurantAnalytics()
    return (
        <DashboardLayout active={'analytics'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        loader={!type}
                        title={`${type == 'concierges' ? 'Concierge' : 'Business'} Analytics`}
                    />
                    <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                </div>
                {loader ?
                    <div className='space-y-3'>
                        <div className='grid xs:grid-cols-3 grid-cols-1 gap-4'>
                            {[0, 1, 2]?.map((itm) => {
                                return (
                                    <AdminAmountCardShimmer />
                                )
                            })}
                        </div>
                        <div className='grid xs:grid-cols-2 grid-cols-1 gap-4'>
                            {[0, 1]?.map((itm) => {
                                return (
                                    <AdminAmountCardShimmer />
                                )
                            })}
                        </div>
                    </div>
                    :
                    <div className='space-y-3'>
                        <div className='grid xs:grid-cols-3 grid-cols-1 gap-4'>
                            <AmountCard
                                amount={data?.total_booking_revenue}
                                title={'Total Booking Revenue'}
                            />
                            <AmountCard
                                amount={type == 'concierges' ? data?.total_concierges_commission : data?.total_bookings_count}
                                title={type == 'concierges' ? 'Total Concierges Commission' : 'Total Booking Count'}
                            />
                            <AmountCard
                                amount={data?.total_platform_commission}
                                title={'Total Platform Gross Commission'}
                            />
                        </div>
                        <div className='grid xs:grid-cols-2 grid-cols-1 gap-4'>
                            <AmountCard
                                amount={type == 'concierges' ? data?.total_concierges : data?.total_restaurants}
                                title={type == 'concierges' ? 'Total Concierges' : 'Total Businesses'}
                            />
                            <AmountCard
                                amount={type == 'concierges' ? data?.active_concierges : data?.active_restaurants} F
                                title={type == 'concierges' ? 'Active Concierges' : 'Active Businesses'}
                            />
                        </div>
                    </div>
                }
                <div className="py-6">
                    <div className="border-b mt-2 mb-5">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "top_bookings" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("top_bookings");
                                }}
                            >
                                <h2>Highest Bookings</h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "top_commissions" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("top_commissions");
                                }}
                            >
                                <h2>
                                    Highest Commission
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-8 space-y-5">

                        <div className="space-y-3">
                            <div className="relative sm:rounded-lg border border-lightGrey">
                                {loader
                                    ? <BookingTableShimer columns={4} />
                                    : <ConciergeRestaurantAnalytics data={data?.top_performers} type={type} />
                                }
                                {data?.length > 0
                                    && <Pagination
                                        currentPage={currentPage}
                                        pageSize={paginationData?.per_page}
                                        totalCount={paginationData?.total_pages}
                                        onPageChange={(page) => setCurrentPage(page)}
                                        totalDataCount={paginationData?.total}
                                        currentPageCount={data?.top_performers?.length}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <ConciergeRestaurantAnalyticsFilter
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                handleChangeFilter={handleChangeFilter}
            />
        </DashboardLayout>
    )
}

export default AdminConciergeRestaurantAnalytics