import Svgs from "Assets/svgs";
import Button from "Components/Elements/Button";
import Image from "Components/Elements/Image";

const RestaurantCardV2 = ({
    title,
    address,
    image,
    index,
    onCardClick,
    showBookNow
}) => {
    return (
        <div
            className="flex flex-col w-full text-center rounded-[20px] text-blueGrey border border-inputBorder shadow-lg overflow-hidden cursor-pointer"
            key={index}
            onClick={(e) => {
                e.stopPropagation();
                if (onCardClick) {
                    onCardClick();
                }
            }}
        >
            {/* Full-width image */}
            <div className="relative w-full h-[100px]">
                {image ? (
                    <Image
                        src={image}
                        className="object-cover w-full h-[100px]"
                        customLoaderHeight="h-[100px]"
                        customLoaderClass={"w-full h-[100px] !rounded-t-[20px]"}
                    />
                ) : (
                    <Image
                        src="/img/dummyRestaurant.png"
                        className="object-cover w-full h-[100px]"
                        customLoaderHeight="h-[100px]"
                        customLoaderClass={"w-full h-[100px] !rounded-t-[20px]"}
                    />
                )}
            </div>

            {/* Content Section */}
            <div className="flex flex-col justify-start text-gray1 gap-1 px-4 pt-2 pb-2">
                <div className="flex justify-between gap-3">
                    <h1 className="text-start font-bold font-grotesk line-clamp-1 text-white">{title}</h1>
                </div>
                <div className="flex gap-2 items-center justify-start">
                    <Svgs.LocationIconV2 />
                    <p className="text-start line-clamp-1 flex-1 break-words break-all text-xs">{address}</p>
                </div>
            </div>
            {showBookNow &&
                <div className="w-full flex-1 pb-2 px-4">
                    <Button
                        text={'Book Now'}
                        className={'w-full'}
                    />
                </div>}
        </div>
    );
};

export default RestaurantCardV2;
