import { getAllSliders } from 'Adapters/APIs/Admin/slider'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useAdminSliders = () => {
    const dispatch = useDispatch()

    // redux states
    const state = useSelector(state => state)
    const sliders = state?.sliders?.sliders
    const pagination_data = state?.sliders?.pagination_data

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    // states
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)
    const [addEditSlider, setAddEditSlider] = useState(false)
    const [editDetail, setEditDetail] = useState(false)

    // get admin slider data
    const getAdminSliderData = () => {
        const success = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        if (currentPage == 1) {
            setLoading(true)
        } else {
            setSecondLoader(true)
        }
        dispatch(getAllSliders(access_token, { page: currentPage, per_page: 10, restaurants_per_page: 1000, is_admin: true }, success, fail))
    }

    // handle successcallback
    const successCallback = () => {
        setCurrentPage(1)
        setLoading(true)
        getAdminSliderData()
    }

    useEffect(() => {
        getAdminSliderData()
    }, [currentPage])

    return {
        sliders, pagination_data, loading, currentPage, setCurrentPage, secondLoader, addEditSlider, setAddEditSlider,
        successCallback, editDetail, setEditDetail
    }
}

export default useAdminSliders