import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import moment from "moment"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

const useAdminExportHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [onDownloadClick, setOnDownloadClick] = useState(false)
    const [downloadFilter, setDownloadFilter] = useState(moment())
    const [downloadLoading, setDownloadLoading] = useState(false)

    // onDOwnloadClick
    const DownloadClick = () => {
        const date = moment(downloadFilter?.monthValue);
        const month = date.format('MM');
        const year = date.format('YYYY');
        const payload = {
            export: 'csv',
            month: +month,
            year: +year
        }
        const success = (res) => {
            setOnDownloadClick(false)
            setDownloadLoading(false)
            if (res?.url) {
                toast.success(res.message, { toastId: 'toast' })
                window.open(res.url)
            } else {
                toast.error(res.message, { toastId: 'toast' })
            }
        }
        const fail = () => {
            setDownloadLoading(false)
        }
        setDownloadLoading(true)
        // dispatch(getInvoiceReportAPI(access_token, payload, success, fail))
    }

    return {
        onDownloadClick, setOnDownloadClick, downloadLoading, DownloadClick, downloadFilter, setDownloadFilter
    }
}

export default useAdminExportHelper
