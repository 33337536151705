import { GET_BILL_REQUEST_DATA } from "./ActionType";

const initialState = {
    billrequest: [],
    billrequest_pagination: {},
}

const BillRequestReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_BILL_REQUEST_DATA:
            let update_data;
            if (payload?.requests?.length == 0) {
                update_data = [];
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                update_data = [...state?.billrequest, ...payload?.requests];
            } else {
                update_data = payload?.requests;
            }
            return {
                ...state,
                billrequest: update_data,
                billrequest_pagination: payload?.pagination_data?.meta
            }
        default:
            return {
                ...state,
            };
    }
}

export default BillRequestReducer