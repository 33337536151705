import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import AnalyticsDashboardCard from './Elements/AnalyticsDashboardCard'
import Back from 'Components/Elements/Back'

const AdminAnalyticsDashboard = () => {
    return (
        <DashboardLayout active={'analytics'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <Back
                    title={'Analytics'}
                />
                <div className="py-6">
                    <div className="!m-0 grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4 p-2">
                        <AnalyticsDashboardCard
                            // icon={<Svgs.GroupUserIcon />}
                            title={'Booking Analytics'}
                            link={'booking-analytics'}
                        />
                        <AnalyticsDashboardCard
                            title={'Concierges Analytics'}
                            link={'concierges-analytics'}
                        />
                        <AnalyticsDashboardCard
                            title={'Businesses Analytics'}
                            link={'restaurants-analytics'}
                        />
                        <AnalyticsDashboardCard
                            title={'Specific Concierge Analytics'}
                            link={'specific-concierge-analytics'}
                        />
                        <AnalyticsDashboardCard
                            title={'Specific Restautrant Analytics'}
                            link={'specific-restaurant-analytics'}
                        />
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default AdminAnalyticsDashboard