import Svgs from "Assets/svgs";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Image from "./Image";
import { error_message } from "Constants/Functions/Variables";

function ImageUpload({
    name,
    required,
    imgSize,
    accept,
    id,
    multiple,
    mb_file_size,
    onChange,
    value,
    imgWidth,
    imgHeight,
    title,
    error,
    errorClass,
    disabled,
    inputPadding,
    customRounded,
    inputClass,
    customColor,
    errorValidation,
    errorText,
}) {
    const inputRef = useRef(null);  // Ref for the input field

    const [image, setImage] = useState(null);
    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');

    // Effect to handle setting the image from URL
    useEffect(() => {
        if (value) {
            if (typeof value == 'string') {
                setImage(value);
                const extractedFileName = value?.split("/").pop(); // Extract file name from URL
                setFileName(extractedFileName || "");
                // Check if the URL ends with .pdf and set fileType accordingly
                if (value.endsWith('.pdf')) {
                    setFileType('pdf');
                } else if (typeof value == 'string' && (value.endsWith('.doc') || value.endsWith('.docx'))) {
                    if (value.endsWith('.doc')) {
                        setFileType('doc');
                    }
                    if (value.endsWith('.docx')) {
                        setFileType('docx');
                    }
                } else if (value.match(/\.(jpeg|jpg|gif|png)$/)) {
                    setFileType('image');
                }
            } else {
                let url = URL.createObjectURL(value);
                setImage(url);
            }
        }
    }, [value]);

    const handleLabelClick = (e) => {
        if (errorValidation) {
            e.preventDefault();
            toast.error(errorText ? errorText : "You can't add more images", { toastId: "errorValidationToast" });
        }
    };

    const handleDelete = () => {
        // Manually set the file input value to an empty string
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        setFileType('')
        setFileName("");
        setImage(undefined)
        // Call onChange with an empty value
        onChange && onChange({
            target: {
                name: name ? name : 'file',
                value: '',
                files: []
            }
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;  // No file selected

        if (multiple) {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];
                let file_size_mb = file.size / 1000 / 1000;
                if (file_size_mb > mb_file_size) {
                    toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "sizeLimitToast" });
                    return;
                }
            }
        } else {
            let file_size_mb = file.size / 1000 / 1000;
            if (file_size_mb > mb_file_size) {
                toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "sizeLimitToast" });
                return;
            }
        }

        const fileExtension = file?.name?.split('.').pop();
        const validFileTypes = accept ? accept.split(',') : ['*'];

        if (!accept ||
            validFileTypes.includes(file.type) || // Check MIME type (like image/png)
            validFileTypes.includes(`.${fileExtension}`) || // Check extension (like .png)
            (accept ? accept : '*')
                ?.split(',')
                ?.map(format => {
                    if (format.startsWith('image/')) {
                        return format.replace('image/', '').trim();
                    } else if (format.startsWith('application/')) {
                        return format.replace('application/', '').trim();
                    }
                    return format.trim();
                })
                .includes(fileExtension)
        ) {
            setFileName(file?.name);
            // Update file type state based on the file extension
            setFileType(fileExtension);

            if (imgWidth && imgHeight) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;
                        if (width <= imgWidth && height <= imgHeight) {
                            onChange && onChange(e);
                            if (!multiple) {
                                let url = URL.createObjectURL(file);
                                setImage(url);
                            }
                        } else {
                            toast.info(`Reduce image resolution to ${imgSize} or lower`, { toastId: "resolutionToast" });
                        }
                    };
                };
                reader.readAsDataURL(file);
            } else {
                onChange && onChange(e);
                if (!multiple) {
                    let url = URL.createObjectURL(file);
                    setImage(url);
                }
            }

            inputRef.current.value = null; // Clear input value
        } else {
            toast.info(`Only ${accept ? accept : 'image/*'} formats are supported.`, { toastId: "toast" });
            return;
        }
    };

    return (
        <div className={`flex flex-col gap-2 flex-1 w-full relative`}>
            {title && (
                <div className={`${title === '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div
                className={`${error ? "inputError border-[1.5px] border-error" : "border-[1.5px] border-lightGrey"} ${(disabled) && 'cursor-not-allowed !bg-gray-800'} ${inputPadding ? inputPadding : "px-2 py-1"} min-h-10 ${customRounded ? customRounded : "rounded-[10px]"} w-full text-[0.875rem] ${customColor ? customColor : "bg-darkGrey"} text-base ${inputClass}`}
            >
                <label
                    htmlFor={id ? id : 'upload_img'}
                    className={`cursor-pointer flex flex-1 items-center rounded-lg bg-darkGrey`}
                    key={id}
                    onClick={handleLabelClick}
                >
                    <div className="bg-lightGrey rounded-md text-white text-sm px-4 py-1.5">Choose File</div>
                    <p className={`text-white normal-case px-2 text-sm`}>{fileName ? fileName : "No file chosen"}</p>
                </label>
                <input
                    type="file"
                    id={id ? id : 'upload_img'}
                    name={name}
                    accept={accept ? accept : 'image/*'}
                    multiple={multiple}
                    autoComplete="off"
                    ref={inputRef}
                    onChange={handleImageChange}
                    hidden
                />
            </div>
            {image &&
                <div className="mt-2 h-[120px] w-[162px] flex-shrink-0 relative">
                    <Image src={image} alt="Uploaded Preview" className="w-full h-[120px] object-cover rounded-lg" />
                    <div
                        className="absolute -top-2 -right-2 border rounded-full cursor-pointer p-2 bg-[#FFE8E1] border-[#FFE8E1]"
                        onClick={handleDelete}
                    >
                        <Svgs.CrossIcon fill={'#FF5722'} />
                    </div>
                </div>
            }

            {error && typeof error == "string" ? (
                <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                    <Svgs.I fill="#eb3b3b" />
                    {error}
                </p>
            ) : typeof error == "boolean" && error == true ? (
                <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" /> {error_message}
                </p>
            ) : (
                ""
            )}
        </div>
    );
}

export default ImageUpload;
