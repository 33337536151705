import React from 'react';
import Svgs from 'Assets/svgs';

const NoInternetPage = () => {
    return (
        <div className="flex flex-col space-y-2 items-center justify-center h-screen bg-primary text-center">
            <Svgs.NoInternetIcon fill={'var(--error-color)'} width={100} height={100} />
            <h1 className="text-4xl font-bold text-error">No Internet Connection</h1>
            <p className="text-lg text-blueGrey mt-4">
                Please check your connection and try again.
            </p>
        </div>
    );
};

export default NoInternetPage;
