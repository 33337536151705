import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import AutoPlaceInput from 'Components/Elements/AutoPlaceInput'
import Button from 'Components/Elements/Button'
import CheckBox from 'Components/Elements/Checkbox'
import DateInput from 'Components/Elements/DateInput'
import InfoIcon from 'Components/Elements/InfoIcon'
import LoginInput from 'Components/Elements/LoginInput'
import PasswordInput from 'Components/Elements/PasswordInput'
import PaymentCheckboxCard from 'Components/Elements/PaymentCheckboxCard'
import PhoneInput from 'Components/Elements/PhoneInput'
import React, { useMemo } from 'react'
import AddBusinessNamePopup from './AddBusinessNamePopup'
import { useNavigate } from 'react-router-dom'
import { error_message } from 'Constants/Functions/Variables'
import Back from 'Components/Elements/Back'

const ConciergeForm = ({ handleChange, errors, step, setStep, formData, setErrors, handleSubmit, loading, businessData, addBusinessName,
    setAddBusinessName, getBusinessNameLoader
}) => {
    const navigate = useNavigate()
    const otherOptions = useMemo(() =>
        [
            {
                method_type: "cash",
                label: "Cash"
            },
            {
                method_type: "bank",
                label: "Bank Transfer"
            },
        ], [])

    const OTHER_PAYMENT_METHODS = [
        { name: 'cash', icon: <Svgs.CashIcon fill={'var(--secondary-color)'} /> },
        { name: 'bank', icon: <Svgs.CardIcon fill={'var(--secondary-color)'} /> },
    ]

    return (
        <>
            <div className="flex flex-col gap-5 w-full">
                <Back
                    onClick={() => {
                        if (step == 1) {
                            navigate(-1)
                        }
                        else {
                            setStep(1)
                        }
                    }}
                    title={"Sign Up"} fill={'var(--secondary-color)'} titleClassName='flex-1 mr-10 !text-4xl !font-semibold text-center !text-white' />

                {step == 1
                    ? <>
                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                            <LoginInput
                                required={true}
                                title={'First Name'}
                                placeholder={"Enter First Name"}
                                value={formData?.first_name}
                                error={errors?.first_name}
                                name={'first_name'}
                                onChange={handleChange}
                                inputPadding={"px-4 py-[9px]"}
                                onlyAlphabetsAllow
                            />
                            <LoginInput
                                required={true}
                                title={'Family Name'}
                                placeholder={"Enter Family Name"}
                                value={formData?.last_name}
                                error={errors?.last_name}
                                name={'last_name'}
                                onChange={handleChange}
                                inputPadding={"px-4 py-[9px]"}
                                onlyAlphabetsAllow
                            />
                        </div>
                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                            <div className='flex-1'>
                                <LoginInput
                                    required={true}
                                    title={'Business/Hotel Name'}
                                    value={
                                        businessData?.length > 0
                                            ? (businessData.find(itm => itm?.id === formData?.business_name)?.name || formData?.business_name)
                                            : formData?.business_name
                                    }
                                    error={errors?.business_name}
                                    name={'business_name'}
                                    onChange={handleChange}
                                    placeholder={"Enter Business/Hotel Name"}
                                    inputPadding={"px-4 py-[9px]"}
                                />
                            </div>
                            {/* <div className='flex gap-2 justify-between items-center'>
                            // add business name component here if want to add popup
                                <div className='pt-7'>
                                    <Button
                                        text={<Svgs.BoldPlusIcon width={"45"} height={'40'} />}
                                        onClick={() => {
                                            setAddBusinessName(true)
                                        }}
                                        className="w-full !py-0.5 !pl-2 !pr-2.5 bg-[#282828] border-[1.5px] border-lightGrey"
                                    />
                                </div>
                            </div> */}
                            <LoginInput
                                title={'Email Address'}
                                required={true}
                                value={formData?.email}
                                error={errors?.email}
                                name={'email'}
                                onChange={handleChange}
                                placeholder={"Enter your Email Address"}
                                inputPadding={"px-4 py-[9px]"}
                            />
                        </div>
                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                            <PhoneInput
                                value={formData?.mobile_number}
                                error={errors?.mobile_number}
                                name={'mobile_number'}
                                onChange={handleChange}
                                required={true}
                                title={'Mobile Number'}
                                placeholder={'Enter Mobile Number'}
                                inputPadding={"px-4 py-[9px]"}
                            />
                            <DateInput
                                title={'Date of Birth'}
                                required={true}
                                type={'date'}
                                value={formData?.dob}
                                error={errors?.dob}
                                name={'dob'}
                                onChange={handleChange}
                                inputPadding={"px-4 py-[9px]"}
                                max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0]}
                            />
                        </div>
                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                            <div>
                                <AutoPlaceInput
                                    value={formData?.address}
                                    error={errors?.address}
                                    setErrors={setErrors}
                                    onChange={handleChange}
                                />
                            </div>

                            <LoginInput
                                value={formData?.referral_code}
                                error={errors?.referral_code}
                                onChange={handleChange}
                                name={'referral_code'}
                                title={<div className='flex items-center'>
                                    Referral Code
                                    <InfoIcon
                                        text={""}
                                        info={<div className='flex flex-col px-2 space-y-2 justify-center text-center max-w-80'>
                                            <h4 className='font-bold px-4 text-white text-center'>Invite your friends to signup and earn rewards when they make bookings!</h4>
                                            <div className='flex flex-col space-y-1 items-start justify-start text-start'>
                                                <h4 className='font-bold text-white'>How it works:</h4>
                                                <p className='text-blueGrey'>Share your referral code with friends. When they signup as a concierge or a business and successfully complete a booking,you'll earn 15 {currency} per booking. You can earn up to 150 {currency} from each invitation, as rewards are limited to the first 10 successful bookings per invitee.</p>
                                            </div>
                                        </div>}
                                    />
                                </div>}
                                placeholder={"Enter Referral Code"}
                                inputPadding={"px-4 py-[9px]"}
                                required={false}
                            />
                        </div>
                        <div className='flex flex-col space-y-2'>
                            <CheckBox
                                checked={formData?.head_of_concierge}
                                error={errors?.head_of_concierge}
                                name={'head_of_concierge'}
                                onChange={handleChange}
                                label={'Would you like to add team members under same account?'}
                            />
                            <CheckBox
                                checked={formData?.terms_condition}
                                error={errors?.terms_condition}
                                name={'terms_condition'}
                                onChange={handleChange}
                                required={true}
                                onClickLabel1={() => {
                                    window.open('https://easyrsv.com/terms-and-conditions', '_blank');
                                }}
                                label={'I agree to the '}
                                colorLabel1={'Terms & Conditions'}
                            />
                            <CheckBox
                                checked={formData?.privacy_policy}
                                error={errors?.privacy_policy}
                                name={'privacy_policy'}
                                onChange={handleChange}
                                required={true}
                                onClickLabel1={() => {
                                    window.open('https://easyrsv.com/privacy', '_blank');
                                }}
                                label={'I agree to the '}
                                colorLabel1={'Privacy Policy'}
                            />
                            <CheckBox
                                checked={formData?.subscription_agreement}
                                error={errors?.subscription_agreement}
                                name={'subscription_agreement'}
                                onChange={handleChange}
                                required={true}
                                label={'I agree to '}
                                onClickLabel1={() => {
                                    window.open('https://easyrsv.com/subscription-agreement', '_blank');
                                }}
                                colorLabel1={'Subscription Agreement Terms & Conditions'}
                            />
                        </div>
                    </>
                    : step == 2
                        ? <>
                            <div className='grid lg:grid-cols-2 md:grid-cols-1 xs:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                                <PasswordInput
                                    title={'Password'}
                                    value={formData?.password}
                                    error={errors?.password}
                                    onChange={handleChange}
                                    name={'password'}
                                    required={true}
                                    placeholder={"Enter Password"}
                                    inputPadding={"px-4 py-[9px]"}
                                />
                                <PasswordInput
                                    title={'Confirm Password'}
                                    value={formData?.password_confirmation}
                                    error={errors?.password_confirmation}
                                    onChange={handleChange}
                                    name={'password_confirmation'}
                                    required={true}
                                    placeholder={"Enter Confirm Password"}
                                    inputPadding={"px-4 py-[9px]"}
                                />
                            </div>
                            <div className='relative'>
                                <div className='grid grid-cols-2 gap-5 py-2'>
                                    {otherOptions?.length > 0 ? (
                                        otherOptions?.map((item, index) => {
                                            let lft_icon = OTHER_PAYMENT_METHODS?.find(
                                                (itm) => itm.name == item.method_type
                                            );
                                            return (
                                                <PaymentCheckboxCard
                                                    key={index}
                                                    leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[1.25rem] w-[1.25rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} />}
                                                    checked={item?.method_type == formData?.payment_method}
                                                    onClick={() => {
                                                        handleChange({
                                                            target: {
                                                                name: 'payment_method',
                                                                value: item?.method_type
                                                            }
                                                        })
                                                    }}
                                                    borderColor={'border-lightGrey'}
                                                    id={item.method_type}
                                                    title={
                                                        <div className='flex items-center gap-2'>
                                                            <p className='font-semibold text-md'>{item.label}</p>
                                                        </div>
                                                    }
                                                />
                                            )
                                        }))
                                        :
                                        ""
                                    }
                                </div>
                                {errors?.payment_method &&
                                    <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                                        <Svgs.I fill="#eb3b3b" /> {error_message}
                                    </p>
                                }
                                <h5 className='text-blueGrey text-xs flex gap-1 items-center'><Svgs.I /> Your commission will be paid directly into your account once per month</h5>
                            </div>
                            {
                                (formData?.payment_method && formData?.payment_method == 'bank') && (
                                    <div className='flex flex-col gap-5'>
                                        <LoginInput
                                            value={formData?.bank_name}
                                            error={errors?.bank_name}
                                            name={'bank_name'}
                                            onChange={handleChange}
                                            required={true}
                                            title={'Bank Name'}
                                            placeholder={"Enter bank name"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                        <LoginInput
                                            value={formData?.card_holder_name}
                                            error={errors?.card_holder_name}
                                            name={'card_holder_name'}
                                            onChange={handleChange}
                                            title={'Account Title'}
                                            required={true}
                                            placeholder={"Enter account title"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                        <LoginInput
                                            required={true}
                                            name={'iban_number'}
                                            title={'Account IBAN'}
                                            charactersMaxLength={34}
                                            onChange={handleChange}
                                            error={errors?.iban_number}
                                            value={formData?.iban_number}
                                            placeholder={"Enter Account IBAN"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                    </div>
                                )
                            }
                        </>
                        : ''
                }
                <div className="py-4">
                    <Button
                        onClick={handleSubmit}
                        text={step == 1 ? "Next" : "Sign Up"}
                        className={'w-full'}
                        disabled={loading}
                        loading={loading}
                    />
                </div>
            </div>

            <AddBusinessNamePopup
                open={addBusinessName}
                close={setAddBusinessName}
                setAddBusinessName={setAddBusinessName}
                getCuisineLoader={getBusinessNameLoader}
                handleChange={handleChange}
                data={businessData}
            />
        </>
    )
}

export default ConciergeForm