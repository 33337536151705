import React from 'react'
import ImageShimmer from './ImageShimmer'

const AdminTransactionCommissionShimmer = () => {
    return (
        <>
            <div className={`bg-lightGrey rounded-lg md:rounded-[10px] min-h-20 flex flex-col gap-3 items-center justify-between p-5`}>
                <div className="w-24 h-6 mb-1 rounded-lg bg-gray-200"></div>
                <div className="w-20 h-4 rounded-lg bg-gray-200"></div>
            </div>
            <div className='my-4 rounded-lg border border-secondary p-4 flex justify-between items-center'>
                <div className='w-48 h-6 rounded-lg bg-gray-200'></div>
                <div className='w-20 h-6 bg-gray-200 rounded-md'></div>
            </div>
            <div className="w-44 h-4 mb-2 rounded-lg bg-gray-200"></div>
            <ImageShimmer />
            <div className="w-full h-12 mb-1 rounded-lg bg-gray-200 mt-2"></div>
        </>
    )
}

export default AdminTransactionCommissionShimmer