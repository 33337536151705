import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import useSingleConciergeBookings from './helper'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import RestaurantCard from 'Pages/Admin/MainDashbaord/Elements/RestaurantCard'
import AdminRestaurantCardShimmer from 'Utility/Shimmers/AdminRestaurantCardShimmer'
import Pagination from 'Components/Pagination'
import ConciergeBookingTable from 'Utility/Tables/ConciergeBookingTable'
import ConciergePayBookingCard from '../Elements/ConciergePayBookingCard'
import Popup from 'Components/Elements/Popup'
import Button from 'Components/Elements/Button'
import { currency } from 'Adapters/variables'
import FilePicker from 'Components/Elements/FilePicker'
import LoginInput from 'Components/Elements/LoginInput'
import Back from 'Components/Elements/Back'
import CheckBox from 'Components/Elements/Checkbox'
import { BOOKING_STATUSES_WITHOUT_ICONS } from 'Constants/Data/BookingStatus'

const SingleConciergeBookings = () => {
    const { conceirgeData, loader, handleChangeMonth, monthValue, bookingLoader, paginationData, bookingData, setCurrentPage,
        currentPage, user_id, startDate, endDate, conciergePay, setConciergePay, payConciergeDues, payClicked, setPayClicked, paidProof,
        onProofChangeHandler, conciergePayLoader, errors, handleBookingClick, selectedBookings, totalManagerEarnings, handleSelectAllBookings
    } = useSingleConciergeBookings()

    const { user, total_commission_generated, bookings } = conceirgeData

    return (
        <DashboardLayout active={'Concierge'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex gap-4 justify-between items-center py-4'>
                    <Back
                        title={'Concierge Details'}
                    />
                    <ReactMonthPicker
                        onChange={handleChangeMonth}
                        value={monthValue}
                        name={'month'}
                        isClearable={true}
                    />
                </div>
                <div className="!m-0">
                    {loader ?
                        <AdminRestaurantCardShimmer />
                        :
                        <RestaurantCard
                            showConciergeDetail={true}
                            conciergeImage={user?.profile_picture}
                            name={`${user?.first_name} ${user?.last_name}`}
                            phoneNumber={`${user?.mobile_code}-${user?.mobile_number}`}
                            address={user?.address}
                            totalBookings={user?.reservations}
                            totalEarned={user?.total_earned}
                            totalDues={user?.due_payments}
                            active={user?.is_active == 1 ? true : false}
                            conciergeTotalCommission={total_commission_generated}
                            conceirgeCommissionPercentage={user?.commission_percentage}
                            unpaidConciergeBookings={bookings?.length}
                            onConciergeViewInvoiceClick={() => { window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/reciept?user_id=${user_id}${startDate ? `&start_date=${startDate}` : ""}${endDate ? `&end_date=${endDate}` : ""}`, '_blank') }}
                            onConciergePayNowClick={() => { setConciergePay(true) }}
                            hideImage
                            hideViewEdit
                        />
                    }
                    {/* <div className="space-y-3 mt-5">
                        <h1 className='font-semibold text-white'>Booking Analytics</h1>
                        <div className="relative sm:rounded-lg border border-table bg-primary">
                            {bookingLoader
                                ? <BookingTableShimer />
                                : <>
                                    <ConciergeBookingTable
                                        data={bookingData}
                                    />
                                    {bookingData?.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            currentPageCount={bookingData?.length}
                                            totalCount={paginationData?.total_pages}
                                            totalDataCount={paginationData?.total}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div> */}
                </div>
            </main>

            <Popup
                open={conciergePay}
                close={setConciergePay}
                size={"sm"}
                heading={'Pay Now'}
            >
                <div className='mb-2 flex justify-end'>
                    <CheckBox
                        name={'concierge_bookings'}
                        required={true}
                        id={'concierge_bookings'}
                        label={'Select All'}
                        checked={selectedBookings?.length == bookings?.length}
                        onChange={handleSelectAllBookings}
                    />
                </div>
                {bookings?.map((itm, ind) => (
                    <ConciergePayBookingCard
                        name={itm?.guest_name}
                        pax={itm?.total_pax}
                        number={itm?.number}
                        date={itm?.date_time}
                        status={itm?.status}
                        selected={selectedBookings?.find((b) => b?.number === itm?.number)}
                        onClick={() => handleBookingClick(itm)}
                        bookingStatusSteps={BOOKING_STATUSES_WITHOUT_ICONS}
                    />
                ))}
                <div className='mt-5'>
                    <Button
                        text={`Pay ${totalManagerEarnings} ${currency}`}
                        className={"w-full"}
                        onClick={() => { setPayClicked(true) }}
                        disabled={selectedBookings?.length == 0}
                    />
                </div>
            </Popup>

            <Popup
                open={payClicked}
                close={setPayClicked}
                customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                heading={'Proceed to payment'}
            >
                <div className="flex flex-col gap-4">
                    <FilePicker
                        required={true}
                        title="Attach transfer proof"
                        value={paidProof}
                        name={'paidProof'}
                        error={errors?.paidProof}
                        id={'paidProof'}
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"}
                        onChange={onProofChangeHandler}
                    />
                    <LoginInput
                        title={'Payable Commission'}
                        required={false}
                        value={conceirgeData?.commission_due}
                        disabled={true}
                        leftIcon={currency}
                    />
                    <Button className={'mt-2'}
                        text={"Submit"}
                        loading={conciergePayLoader}
                        disabled={conciergePayLoader}
                        onClick={payConciergeDues}
                    />
                </div>
            </Popup>


        </DashboardLayout>
    )
}

export default SingleConciergeBookings