import { getBillRequestApi } from 'Adapters/APIs/Restaurant/BillRequest'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const initialFilterState = {
    bill_upload: 0,
    payment: 0,
    status: ""
}

const useRestaurantBillRequestHelper = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // redux states
    const state = useSelector(state => state)
    const billRequest = state?.billRequest?.billrequest
    const pagination = state?.billRequest?.billrequest_pagination

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [appliedFilter, setAppliedFilter] = useState()

    const prevFilterDataRef = useRef(initialFilterState);


    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value, checked } = e.target
        if (name == 'bill_upload' || name == 'payment') {
            setFilterData((prev) => ({
                ...prev,
                [name]: checked ? 1 : 0
            }))
        } else {
            setFilterData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            setCurrentPage(1)
            setLoading(true)
            getBillRequest()
        }
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        setAppliedFilter()
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            getBillRequest(false, true)
            setFilterData(initialFilterState)
        }
    }

    // get bill request data
    const getBillRequest = (noLoading, noFilter) => {
        const success = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }

        let payload = {
            page: currentPage,
            per_page: 20,
            restaurant_id: user_data?.restaurant?.id ? user_data?.restaurant?.id : ""
        }
        if (!noFilter) {
            payload = {
                ...payload,
                ...filterData
            }
            delete payload.dropdownValue
            setAppliedFilter({
                status: payload?.status,
                bill_upload: payload?.bill_upload,
                payment: payload?.payment,
            })
        }
        if (!noLoading) {
            if (currentPage == 1) {
                setLoading(true)
            } else {
                setSecondLoader(true)
            }
        }
        dispatch(getBillRequestApi(access_token, payload, success, fail))
    }

    useEffect(() => {
        getBillRequest()
    }, [currentPage])

    useEffect(() => {
        const prevFilterData = prevFilterDataRef.current;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            setCurrentPage(1)
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current state for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);


    return {
        billRequest, pagination, loading, currentPage, setCurrentPage, addFilter, setAddFilter, secondLoader, appliedFilter, onApplyFilter,
        onClearFilter, handleChangeFilter, filterData, setFilterData, navigate
    }
}

export default useRestaurantBillRequestHelper