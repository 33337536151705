import { currency } from 'Adapters/variables'
import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const RestaurantTotalRecievableTable = ({ data }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white border-b border-table bg-primary capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Created At
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Confirmed At
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Booking Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Total Bill
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Total Tip
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                My Earning
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Guest
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                Platform Commission
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap ">
                                My Recievable
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary">
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.number}
                                            </td>
                                            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.created_at ? moment(itm?.created_at)?.format('DD-MM-YYYY') : "----"}
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.confirmed_at ? moment(itm?.confirmed_at)?.format('DD-MM-YYYY') : "----"}
                                            </th>
                                            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                {itm?.date_time ? moment(itm?.date_time)?.format('DD-MM-YYYY') : "----"}
                                            </th>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.amount} {currency}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.end_user_transaction?.tip_amount ? itm?.end_user_transaction?.tip_amount : 0.00} {currency}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.restaurant_earning} {currency}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.total_pax}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {itm?.commission} {currency}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap ">
                                                {((itm?.end_user_transaction?.tip_amount ? +itm?.end_user_transaction?.tip_amount : 0.00) + +itm?.restaurant_earning)?.toFixed(2)} {currency}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default RestaurantTotalRecievableTable