import Svgs from 'Assets/svgs'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Back = ({ titleClassName, fill, mainClass, onClick, title, loader, hideIcon, noPadding }) => {
    const navigate = useNavigate()
    return (
        <div className={`${mainClass} flex gap-4 z-40 items-center ${noPadding ? "" : "py-4"} lg:flex-1 topscroll`}>
            {!hideIcon &&
                <Svgs.BackIcon2
                    className={"cursor-pointer"}
                    fill={fill}
                    onClick={() => {
                        if (onClick) {
                            onClick()
                        }
                        else {
                            navigate(-1)
                        }
                    }}
                />
            }
            {loader ?
                <div className='animate-pulse h-8 w-36 bg-gray-200 rounded-md'></div>
                :
                title ?
                    <h2 className={`${titleClassName ? titleClassName : "font-semibold xs:text-2xl text-xl text-secondary whitespace-nowrap"}`}>{title}</h2>
                    : ""
            }
        </div>
    )
}

export default Back