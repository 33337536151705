import Svgs from "Assets/svgs";
import { error_message } from "Constants/Functions/Variables";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const MultiDropdown = ({
    leftRounded,
    className,
    title,
    name,
    value,
    onBlur,
    placeholder,
    options,
    onChange,
    error,
    disabled,
    required,
    noBorder,
    showTitle,
    edit,
    handleFocus,
    info_icon,
    minWidth,
    bgColor
}) => {

    const [field_err, setFieldErr] = useState(undefined)

    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: noBorder
                ? 0
                : isFocused
                    ? "1.5px solid var(--lightGrey-color)"
                    : "1.5px solid var(--lightGrey-color)",
            color: "#FFFFFF",
            backgroundColor: bgColor ? bgColor : '#282828',
            fontSize: "0.875rem",
            padding: "0.16rem 0.2rem",
            outline: "none",
            borderRadius: leftRounded ? "10px 0 0 10px" : '10px',
            // minWidth: option.find((itm) => itm.value == value) ? `${(option.find((itm) => itm.value == value).label.length * 0.625 + 3) > 12 ? 12 : (option.find((itm) => itm.value == value).label.length * 0.625 + 3)}rem` : '12rem' // Adjust the width calculation as needed
            // minWidth: '10rem'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#FFFFFF",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isDisabled
                ? "#282828"
                : isFocused
                    ? "#d6b560"
                    : bgColor ? bgColor : "#282828",
            color: isFocused ? "#0e0e0e" : "#FFFFFF",
            fontSize: "0.875rem",
            cursor: isDisabled ? "not-allowed" : "pointer",
        }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: bgColor ? bgColor : '#282828',
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
            zIndex: 111111111111,
        }),
        input: (styles) => ({
            ...styles,
            '[type="text"]': {
                color: '#FFFFFF !important'
            }
        })
    };

    const errorStyle = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: field_err && "1.5px solid var(--error-color)",
            color: "#FFFFFF",
            backgroundColor: bgColor ? bgColor : '#282828',
            fontSize: "0.875rem",
            padding: "0.16rem 0.2rem",
            outline: "none",
            borderRadius: leftRounded ? "10px 0 0 10px" : '10px',
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            background: isDisabled
                ? "#4e4e4e"
                : isFocused
                    ? "#d6b560"
                    : bgColor ? bgColor : "#4e4e4e",
            color: isFocused ? "#0e0e0e" : "#fff",
            fontSize: "0.875rem",
            cursor: isDisabled ? "not-allowed" : "pointer",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#FFFFFF",
        }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: bgColor ? bgColor : '#4e4e4e',
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
            zIndex: 111111111111,
        }),
        input: (styles) => ({
            ...styles,
            '[type="text"]': {
                color: '#FFFFFF !important'
            }
        })
    };


    const customFormatOptionLabel = ({ label, otherData }) => (
        <div className='flex items-center gap-3 h-[1.125rem] z-[10]'>
            <div className="text-sm gap-2">
                <div className="text-sm font-medium">{label}</div>
                <div className="overflow-hidden whitespace-nowrap text-[8px] leading-normal">{otherData?.DataOne && otherData.DataOne}</div>
            </div>
        </div>
    );


    useEffect(() => {
        setFieldErr(error)
    }, [error])


    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const handleMenuClose = () => {
        setTimeout(() => {
            setMenuIsOpen(false);
        }, 100);
    };

    const selectedOptions = value?.length > 0 ? value?.map(value =>
        options.find(option => option?.value === value)
    ) : []

    const handleChange = (selectedOptions) => {
        if (onChange) {
            onChange({ target: { name: name, value: selectedOptions?.length > 0 ? selectedOptions?.map(option => option?.value) : [] } });
        }
    };

    return (
        <>
            <div
                className={`flex flex-col gap-2 flex-1 whitespace-nowrap capitalize relative ${minWidth ? minWidth : ''} ${className} ${disabled ? "cursor-not-allowed" : ""
                    }`}
            >
                {title != "" && (
                    <p className="text-sm flex gap-1 capitalize text-blueGrey">
                        {title && title}
                        <span className="text-red-600">
                            {required && required ? " * " : ""}
                        </span>
                        {info_icon && info_icon}
                    </p>
                )}
                <Select
                    styles={field_err ? errorStyle : colourStyles}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    name={name}
                    className="border-0"
                    formatOptionLabel={customFormatOptionLabel}
                    menuPosition="fixed"
                    isMulti={true}
                    menuShouldBlockScroll
                    isSearchable={true}
                    menuPortalTarget={document.body}
                    classNamePrefix="custom"
                    onBlur={(e) => {
                        onBlur({ target: { name: name } });
                    }}
                    value={selectedOptions}
                    // value={
                    //     value?.length > 0
                    //         ? value?.map((val) => ({
                    //             value: val.value,
                    //             label: options?.find((itm) => itm.value == val.value)?.label || "",
                    //         }))
                    //         : placeholder
                    // }
                    options={options && options.length > 0 ? options : []}
                    onChange={handleChange}
                    isDisabled={edit ? false : options.length == 0 ? true : disabled}
                    placeholder={placeholder}
                    isOptionDisabled={(option) => option.disabled}
                    onFocus={() => {
                        if (handleFocus) {
                            handleFocus();
                        }
                    }}
                />


                {field_err && typeof field_err == "string" ? (
                    <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end normal-case pt-1`}>
                        <Svgs.I fill="#eb3b3b" />
                        {field_err}
                    </p>
                ) : typeof field_err == "boolean" && field_err == true ? (
                    <p className={`text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end normal-case pt-1`}>
                        <Svgs.I fill="#eb3b3b" />
                        {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

MultiDropdown.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    options: [
        // { value: 'no_value', label: 'Enter "options" prop' },
    ],
    name: "no_name",
    value: "no_value",
    onBlur: () => { },
    onChange: () => { },
    error: false,
    disabled: false,
    required: false,
    noBorder: false,
    showTitle: true,
};

export default MultiDropdown;