import { getRestaurantTotalRecievables } from "Adapters/APIs/Admin/analytics"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

let initialFilterState = {
    start_date: "",
    end_date: "",
    filter: "",
    date_filter_type: "date"
}
const useRestaurantTotalRecievableHelper = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage("user_data")
    const defaultRoute = getDataFromLocalStorage("defaultRoute")

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            setCurrentPage(1)
            getRestaurantTotalRecievablesData()
        }
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            setCurrentPage(1)
            getRestaurantTotalRecievablesData(true)
            setFilterData(initialFilterState)
        }
    }

    // get booking analytics data
    const getRestaurantTotalRecievablesData = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10
        }
        if (!noFilter) {
            payload = {
                ...payload,
                ...filterData
            }
        }
        setLoader(true)
        const success = (res) => {
            setData(res)
            setPaginationData(res?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        if (defaultRoute == "/restaurant/dashboard") {
            payload = {
                ...payload,
                restaurant_id: user_data?.restaurant?.id
            }
        }
        dispatch(getRestaurantTotalRecievables(access_token, payload, success, fail))
    }

    useEffect(() => {
        getRestaurantTotalRecievablesData()
    }, [currentPage])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        paginationData, currentPage, setCurrentPage, filterData, setFilterData, loader, data, addFilter, setAddFilter, handleChangeFilter,
        onClearFilter, onApplyFilter
    }
}

export default useRestaurantTotalRecievableHelper