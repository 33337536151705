import React from 'react'
import Button from 'Components/Elements/Button'
import Popup from 'Components/Elements/Popup'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import { BILL_REQUEST_STATUS } from 'Constants/Data/Statuses'
import Svgs from 'Assets/svgs'

const BillRequestFilter = ({ open, close, onClearFilter, onApplyFilter, filterData, setFilterData, handleChangeFilter, dropdownOptions }) => {

    // handle on clear filters
    const handleOnClearFilters = () => {
        onClearFilter && onClearFilter()
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Filter by'}
        >
            <div className='flex flex-col space-y-4'>
                <div className='flex justify-between gap-2 items-center text-blueGrey'>
                    Bill Upload
                    <ToggleWithText
                        name={'bill_upload'}
                        checked={filterData?.bill_upload}
                        onChange={handleChangeFilter}
                    />
                </div>
                <div className='flex justify-between gap-2 items-center text-blueGrey'>
                    Payment
                    <ToggleWithText
                        name={'payment'}
                        checked={filterData?.payment}
                        onChange={handleChangeFilter}
                    />
                </div>
                <div className='flex flex-col justify-between gap-2 text-blueGrey'>
                    Request
                    <div className='xs:grid xs:grid-cols-3 flex flex-row flex-wrap gap-[10px]'>
                        {BILL_REQUEST_STATUS?.map(itm => (
                            <span
                                key={itm?.value}
                                className={`${itm?.value == filterData?.status ? "bg-[#373737]" : "bg-transparent"} border border-[#686868] px-3.5 py-1.5 xs:w-full w-fit rounded-[10px] text-xs font-normal flex justify-center items-center gap-2 cursor-pointer`}
                                onClick={() => {
                                    setFilterData((prev) => ({
                                        ...prev,
                                        status: itm?.value
                                    }))
                                }}
                            >
                                {itm?.label}
                                {itm?.value == filterData?.status &&
                                    <Svgs.Tick stroke={'white'} />}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} />
                    <Button text={'Apply'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default BillRequestFilter