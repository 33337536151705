import React from 'react'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import CreateBooking from 'Pages/Concierges/Bookings/CreateBookings'
import DashboardLayout from 'Components/DashboardLayout'
import useSingleRestaurant from './helper'
import SingleAdminRestaurantDetailShimmer from 'Utility/Shimmers/SingleAdminRestaurantDetailShimmer'
import Back from 'Components/Elements/Back'
import SingleRestaurantRatingPupup from 'Pages/Admin/Restaurants/SingleRestaurant/SingleRestaurantRatingPupup'
import { currency } from 'Adapters/variables'
import RestaurantBusinessHour from 'Pages/Admin/Restaurants/SingleRestaurant/SingleRestaurantRatingPupup/RestaurantBusinessHour'
import { toast } from 'react-toastify'
import DummyImage from "../../../../Assets/images/dummtImg.png"
import Image from 'Components/Elements/Image'
import ImageGalleryPopup from 'Pages/Restaurants/Profile/Elements/ImageGalleryPopup'
// import Slider from '../Elements/Slider'

const RestaurantDetail = () => {
    const { showRatingPopup, setShowRatingPopup, loader, restaurant, showBusinessHour, setShowBusinessHour, openGallery, setOpenGallery,
        createBookingPopup, setCreateBookingPopup, navigate } = useSingleRestaurant()
    const {
        id, name, ratings, average_rating, total_rating, categories, price_per_cover, cuisine, images, terms_and_conditions, description,
        phone_code, phone_number, email, website_link, business_hours, booking_cancellation_terms, mobile_number, mobile_code, instagram_link,
        contact_person, authorized_manager, display_picture
    } = restaurant


    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {loader ?
                        <SingleAdminRestaurantDetailShimmer hideSliderShimmer restaurantDetail={true} />
                        : <div className="space-y-5">
                            <div className="flex items-center justify-between gap-5">
                                <Back title={name} />
                                <Button
                                    onClick={() => {
                                        setCreateBookingPopup(id)
                                    }}
                                    text={'Book now'}
                                    className={'w-fit !rounded'}
                                />
                            </div>
                            {/* <div className='flex justify-between gap-2'>
                                <div className='flex flex-col space-y-2  text-sm'>
                                    <div className="flex gap-2 items-center text-[#b0b0b0]">
                                        <span><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></span>
                                        <span className="font-extralight">{address}</span>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='grid grid-cols-1 justify-between items-center gap-4'>
                                <div className='flex flex-col space-y-2'>
                                    <h1 className='text-white'>Profile Images</h1>
                                    <Slider data={images} cardClass={"h-60"} />
                                </div>
                            </div> */}
                            <div className="flex items-start gap-5 lg:flex-row flex-col !mt-2">
                                <div className="flex-1 w-full">
                                    {images.length > 0 ?
                                        <Image
                                            src={display_picture ? display_picture : images[0].url}
                                            className='w-full max-h-[400px] aspect-video object-cover rounded-[20px]'
                                            customLoaderClass={'w-full max-h-[400px] aspect-video object-cover rounded-[20px]'}
                                            customLoaderHeight={'max-h-[400px]'}
                                            alt="" />
                                        :
                                        <Image src={DummyImage} className='w-full max-h-[400px] aspect-video object-cover rounded-[20px]' alt="" />
                                    }
                                </div>
                                <div className='relative'>
                                    {images?.length > 4 && (
                                        <div
                                            className="flex z-10 absolute rounded-lg px-2.5 cursor-pointer bottom-3 right-3 py-1.5 text-xs gap-1.5 bg-white"
                                            onClick={() => setOpenGallery(!openGallery)}
                                        >
                                            <Svgs.GalleryIcon />
                                            Show All Photos
                                        </div>
                                    )}
                                    {images?.length > 1 &&
                                        <div className="xl:w-64 lg:w-52 w-full lg:flex grid grid-cols-3 lg:flex-col gap-3 overflow-x-auto ">
                                            {images?.slice(1, 4).map((image) => {
                                                return <Image
                                                    src={image?.url}
                                                    className='lg:w-full lg:max-h-[120px] aspect-video object-cover rounded-[10px]'
                                                    customLoaderHeight={'max-h-[120px]'}
                                                    alt="" />
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='flex flex-wrap whitespace-nowrap justify-between gap-4 items-center'>
                                <div className='flex flex-1 gap-4 items-center'>
                                    <div className="bg-darkGrey rounded-full flex flex-col gap-3 items-center justify-between py-2 xs:px-[15px] px-2  cursor-pointer" onClick={() => {
                                        if (ratings) {
                                            setShowRatingPopup('rating')
                                        } else {
                                            toast.error('No rating Found', { toastId: 'toast' })
                                        }
                                    }}>
                                        <div className="flex gap-2 items-center">
                                            <Svgs.StarIcon className={'size-4'} />
                                            <p className='text-white font-bold text-xs'>View Ratings</p>
                                        </div>
                                    </div>
                                    <div className="bg-darkGrey rounded-full flex flex-col gap-3 items-center justify-between py-2 xs:px-[15px] px-2 cursor-pointer" onClick={() => { navigate(`/concierge/restaurant-feedback/${id}`) }}>
                                        <div className="flex gap-2 items-center">
                                            {average_rating > 0 && <Svgs.StarIcon />}
                                            <p className='text-white font-bold text-xs'>{average_rating} ({total_rating ? total_rating : 0} Reviews)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <Button
                                        onClick={() => {
                                            setShowBusinessHour(!showBusinessHour)
                                        }}
                                        className={'w-full sm:w-fit flex-end bg-transparent px-4 border-secondary border rounded-[10px] py-2 flex items-center gap-2'}
                                    >
                                        <Svgs.ColoredClockIcon stroke={'var(--secondary-color)'} />
                                        <span className="text-xs font-bold text-secondary">Business Hours</span>
                                    </Button>
                                    <Button
                                        customPadding={'rounded-[10px] px-4 py-2 gap-1.5'}
                                        onClick={() => { navigate(`/concierge/restaurant-menu/${id}`) }}
                                        title={<>
                                            <Svgs.MenuIcon className={'size-5'} fill={'var(--primary-color)'} />
                                            <span className="text-xs font-bold text-black">Menu</span>
                                        </>}
                                    />
                                </div>
                            </div>
                            <div className='grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4'>
                                <div className="rounded-[20px] p-4 bg-darkGrey flex items-center gap-3">
                                    <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                        <Svgs.ColoredPhoneIcon className={'size-6'} />
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-secondary">Contact</p>
                                        <p className="text-white">
                                            {phone_number && phone_number != 'null' ? `+${phone_code} ${phone_number}` : mobile_number && mobile_number != 'null' ? `+${mobile_code} ${mobile_number}` : "----"}
                                        </p>
                                    </div>
                                </div>
                                <div className="rounded-[20px] p-4 bg-darkGrey flex items-center gap-3">
                                    <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                        <Svgs.Mail className={'size-6'} />
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-secondary">Email</p>
                                        <p className="text-white break-all">
                                            {email}
                                        </p>
                                    </div>
                                </div>
                                <div className="rounded-[20px] p-4 bg-darkGrey flex items-center gap-3">
                                    <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                        <Svgs.Web className={'size-6'} />
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-secondary">Website</p>
                                        <p className="text-white break-all">
                                            {website_link ? website_link : "No URL"}
                                        </p>
                                    </div>
                                </div>
                                <div className="rounded-[20px] p-4 bg-darkGrey flex items-center gap-3">
                                    <div className="fill-[#A47500] bg-secondary p-[10px] rounded-[10px]">
                                        <Svgs.Insta className={'size-6'} />
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-secondary">Instagram</p>
                                        <p className="text-white break-all">
                                            {instagram_link ? instagram_link : "No URL"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='space-y-7'>
                                <div className='grid xl:grid-cols-5 lg:grid-cols-3 grid-cols-2 divide-x text-center p-7 border-y gap-x-2 gap-y-10  divide-[#434343] border-solid border-[#434343]'>
                                    <div className="flex flex-col">
                                        <h1 className='font-semibold  text-secondary'>Price per cover</h1>
                                        <p className='font-semibold text-sm text-white'>{price_per_cover ? `${price_per_cover} ${currency}` : "-----"} </p>
                                    </div>
                                    <div className="flex flex-col !border-transparent lg:!border-[#434343]">
                                        <h1 className="font-semibold  text-secondary">Category</h1>
                                        <p className="font-semibold text-sm text-white break-words">
                                            {categories?.length > 0 ? categories?.map((itm, index) => (
                                                <span key={index}>
                                                    {itm?.name}{index !== categories?.length - 1 && ", "}
                                                </span>
                                            )) : "-----"}
                                        </p>
                                    </div>
                                    <div className="flex flex-col">
                                        <h1 className='font-semibold  text-secondary'>Sub Category</h1>
                                        <p className='font-semibold text-sm text-white'>{cuisine?.name ? cuisine?.name : "-----"}</p>
                                    </div>
                                    <div className="flex flex-col lg:!border-transparent xl:!border-[#434343]">
                                        <h1 className='text-secondary font-semibold'>Contact Person</h1>
                                        <p className='font-semibold text-sm text-white'>{contact_person ? `${contact_person}` : "-----"} </p>
                                    </div>
                                    <div className="flex flex-col !border-transparent lg:!border-[#434343] col-span-2 lg:col-span-1">
                                        <h1 className='text-secondary font-semibold'>Main point of contact</h1>
                                        <p className='font-semibold text-sm text-white'>{authorized_manager ? authorized_manager : "-----"}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <h1 className='text-white sm:text-lg font-semibold'>Description:</h1>
                                    <p className='text-white text-sm'>{description ? description : "-----"}</p>
                                </div>
                                <div className="flex flex-col">
                                    <h1 className='text-white sm:text-lg font-semibold'>Booking cancellation terms:</h1>
                                    <p className='text-white text-sm'>{booking_cancellation_terms ? "Credit card required for booking" : "Credit card not required for booking"}</p>
                                </div>
                                <div className="flex flex-col">
                                    <h1 className='text-white sm:text-lg font-semibold'>Terms and Conditions:</h1>
                                    <p className='text-white text-sm whitespace-pre-wrap'>{terms_and_conditions ? terms_and_conditions : "-----"}</p>
                                </div>
                            </div>
                        </div>
                    }
                </main>
                {createBookingPopup && <CreateBooking
                    selectRestaurant={createBookingPopup}
                    close={() => setCreateBookingPopup()}
                />}
                {(showRatingPopup == 'rating') &&
                    <SingleRestaurantRatingPupup
                        open={showRatingPopup}
                        close={setShowRatingPopup}
                        ratingData={ratings}
                    />
                }

                <RestaurantBusinessHour
                    open={showBusinessHour}
                    close={setShowBusinessHour}
                    data={business_hours}
                />

                <ImageGalleryPopup
                    images={images}
                    // name={formData?.restaurant_name}
                    open={openGallery}
                    close={() => { setOpenGallery(false) }}
                />
            </DashboardLayout>
        </>
    )
}

export default RestaurantDetail