import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAddEditSlider from './helper'
import MultiDropdown from 'Components/Elements/MultiDropdown'
import StatusInput from 'Components/Elements/StatusInput'
import InputShimmer from 'Utility/Shimmers/InputShimmer'
import Dropdown from 'Components/Elements/Dropdown'
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/Elements/Checkbox'

const AddEditSlider = ({ open, close, editData, successCallBack }) => {
    const { data, loader, updateOrAddSliderData, handleChange, errors, handleClose, dropdown_restaurants, getRestaurantLoader,
        handleRemoveRestaurants, handleChangeCheckbox
    } = useAddEditSlider({ editData, close, successCallBack })
    return (
        <Popup
            open={open}
            close={handleClose}
            heading={editData ? "Edit Slider" : "Add Slider"}
            customSizeStyle={'sm:w-[45vw] md:w-[50vw] lg:w-[50vw]'}
        >
            <div className="flex flex-col space-y-5">
                <LoginInput
                    required={true}
                    title={'Name'}
                    placeholder={'Enter Name'}
                    value={data?.name}
                    name={'name'}
                    onChange={handleChange}
                    error={errors?.name}
                />
                <div>
                    {getRestaurantLoader ?
                        <InputShimmer /> :
                        <>
                            <Dropdown
                                required={true}
                                title={'Select Businesses'}
                                placeholder={'Select Businesses'}
                                name={'restaurants'}
                                onChange={handleChange}
                                value={[]}
                                inputClass={"min-w-[180px]"}
                                option={dropdown_restaurants?.filter(itm => !data?.restaurants?.includes(itm?.id))?.map(itm => {
                                    return {
                                        value: itm?.id,
                                        label: itm?.name
                                    }
                                })}
                                error={errors?.restaurants}
                            />
                            {data?.restaurants?.length > 0 ?
                                <div className='pt-4 max-h-[200px] overflow-y-auto'>
                                    <div className='flex flex-wrap gap-3 items-center'>
                                        {data?.restaurants?.map((itm, ind) => {
                                            const matchedRest = dropdown_restaurants?.find(res => res?.id == itm)
                                            return (
                                                <div className='rounded border border-border px-2 py-1 flex items-center gap-2 text-xs text-blueGrey'>
                                                    {matchedRest?.name}
                                                    <div className='cursor-pointer' onClick={() => { handleRemoveRestaurants(itm) }}>
                                                        <Svgs.CrossIcon2 height={12} width={12} fill={'var(--error-color)'} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                : ""
                            }
                            {/* <MultiDropdown
                            required={true}
                            title={'Select Businesses'}
                            placeholder={'Select Businesses'}
                            name={'restaurants'}
                            onChange={handleChange}
                            value={data?.restaurants}
                            inputClass={"min-w-[180px]"}
                            options={[...dropdown_restaurants?.map(itm => {
                                return {
                                    value: itm?.id,
                                    label: itm?.name
                                }
                            })]}
                            error={errors?.restaurants}
                        /> */}
                        </>
                    }
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={` text-sm text-blueGrey flex items-center gap-1`}>
                        <div>Slider Visibility</div> <span className="leading-[1] text-red-600"> *</span>
                    </div>
                    <div className='flex flex-wrap gap-6 items-center'>
                        <CheckBox
                            checked={data?.platform_visibility == 0}
                            name={'platform_visibility'}
                            onChange={() => { handleChangeCheckbox(0) }}
                            label={'EasyRSV Pro'}
                            parentClass={"!gap-2"}
                        />
                        <CheckBox
                            checked={data?.platform_visibility == 1}
                            name={'platform_visibility'}
                            onChange={() => { handleChangeCheckbox(1) }}
                            label={'EasyRSV'}
                            parentClass={"!gap-2"}
                        />
                        <CheckBox
                            checked={data?.platform_visibility == 2}
                            name={'platform_visibility'}
                            onChange={() => { handleChangeCheckbox(2) }}
                            label={'Both'}
                            parentClass={"!gap-2"}
                        />
                    </div>
                </div>
                {editData?.slider?.id &&
                    <StatusInput
                        title={"Status"}
                        required
                        name={"is_active"}
                        value={data?.is_active}
                        onChange={handleChange}
                    />
                }
                <div className='pt-2'>
                    <Button
                        className={'w-full'}
                        title={editData ? "Save" : "Add"}
                        loading={loader}
                        disabled={loader}
                        onClick={updateOrAddSliderData}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default AddEditSlider