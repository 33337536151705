import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useSingleRestaurantMenu from './helper'
import Accordion from 'Components/Elements/Accordian'
import ButtonShimmer from 'Utility/Shimmers/ButtonShimmer'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import DownloadMenuPopup from './DownloadMenuPopup'
import AddMenuPopup from 'Pages/Restaurants/Menu/AddMenu'
import DeletePopup from 'Pages/Restaurants/Menu/DeleteVerificationPopup'
import Svgs from 'Assets/svgs'

const SingleRestaurantMenu = ({ active, isAdmin }) => {
    const {
        menuData, loader, activeTab, setActiveTab, pdfMenuLoader, menuPopup, setMenuPopup, restuarant_id,
        addPopup, setAddPopup, deletePopup, setDeletePopup, onSuccess, loading, deleteMainMenu, pdfData,
        deleteConfirmation, setDeleteConfirmation, pDFDeleteLoader, setPDFDeleteLoader, deletePDF
    } = useSingleRestaurantMenu(isAdmin)
    return (
        <DashboardLayout active={active ? active : 'Restaurant'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex justify-between items-center gap-4'>
                    <Back
                        title={'Menu'}
                    />
                    <div className="flex gap-4">
                        {isAdmin &&
                            <Button
                                text={'Add Menu'}
                                onClick={() => { setAddPopup(!menuPopup) }}
                                customPadding={"px-4 py-2"}
                            />
                        }
                    </div>
                </div>
                <div className="!m-0">
                    <div className="border-b mt-2">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "MainMenu" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("MainMenu");
                                }}
                            >
                                <h2>Main Menu</h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "BeverageMenu" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("BeverageMenu");
                                }}
                            >
                                <h2>
                                    Bevearge Menu
                                </h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "DessertMenu" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("DessertMenu");
                                }}
                            >
                                <h2>
                                    Desset Menu
                                </h2>
                            </div>
                        </div>
                    </div>

                    {!pdfData && (menuData?.length == 0 && !loader) ?
                        <div className="p-4 flex flex-col items-center justify-center">
                            <NotFound />
                        </div>
                        :
                        <>
                            <div className="pt-6 pb-2 flex-col flex space-y-2">
                                <h2 className='text-white text-xl'>Pdf Menu</h2>
                                {(pdfMenuLoader) ?
                                    <div className='bg-gray-500 rounded-md w-full h-14'></div>
                                    : pdfData ?
                                        <div className={`px-4 flex justify-between gap-3 bg-darkGrey text-blueGrey items-center p-2 rounded-xl shadow attach-file-btn`}>
                                            <div className="flex items-center gap-2 line-clamp-1">
                                                <div><Svgs.PdfFileIcon className={'w-6 h-7'} /></div>
                                                <div className='break-words text-white font-semibold'>{pdfData}</div>
                                            </div>
                                            <div className='flex justify-between items-center gap-2'>
                                                {isAdmin &&
                                                    <button className='cursor-pointer text-secondary bg-black !px-4 !py-2 pt-2 rounded-lg md:rounded-[10px] border border-secondary text-secondary text-xs font-semibold flex items-center gap-2'
                                                        onClick={() => { setDeleteConfirmation(!deleteConfirmation) }}
                                                    >
                                                        <Svgs.BinV2 className={''} />
                                                        <p className="">Delete</p>
                                                    </button>
                                                }
                                                <button className='cursor-pointer bg-secondary text-darkGrey !px-4 !py-2 pt-2 rounded-lg md:rounded-[10px] border border-secondary text-secondary text-xs font-semibold flex items-center gap-2' onClick={() => {
                                                    const pdfWindow = window.open(pdfData, '_blank'); // Opens the PDF in a new tab
                                                    if (pdfWindow) {
                                                        pdfWindow.focus(); // Focus on the new tab
                                                    }
                                                }}>
                                                    <Svgs.EyeHide fill={'--darkGrey-color'} className={'!fill-[--darkGrey-color]'} />
                                                    <p className="">View</p>
                                                </button>
                                            </div>
                                        </div> : <NotFound />
                                }
                            </div>
                            <div className="pt-6 pb-2 flex-col flex space-y-2">
                                <h2 className='text-white text-xl'>Manual Menu</h2>
                                <Accordion
                                    items={menuData}
                                    showEdit={isAdmin ? true : false}
                                    showDelete={isAdmin ? true : false}
                                    contentClass={'relative'}
                                    loader={loader}
                                    onDeleteClick={(id) => setDeletePopup({ id: id })}
                                    onEditClick={(itm) => setAddPopup(itm)}
                                    showBorder={false}
                                    type={activeTab}
                                    showCardBackground={false}
                                />
                            </div>
                        </>
                    }
                </div>
            </main>

            <AddMenuPopup
                isAdmin={restuarant_id}
                open={addPopup}
                close={setAddPopup}
                onSuccessCallback={onSuccess}
                type={activeTab == 'MainMenu' ? 'main_menu' : activeTab == "BeverageMenu" ? "beverage_menu" : "dessert_menu"}
            />

            {/* Delete Popup */}
            <DeletePopup
                open={deletePopup && deletePopup?.id}
                close={setDeletePopup}
                title={<div className='text-white'>
                    {deletePopup?.type
                        ? 'Are you sure you want to delete the menu item?'
                        : 'Are you sure you want to delete the menu?'}
                </div>
                }
                onDelete={deleteMainMenu}
                loading={loading}
            />
            {/* Delete PDF */}
            <DeletePopup
                open={deleteConfirmation}
                close={setDeleteConfirmation}
                title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                    <h2 className='text-white'>Are you sure you want to Delete?</h2>
                </div>}
                loading={pDFDeleteLoader}
                onDelete={deletePDF}
            />
        </DashboardLayout>
    )
}

export default SingleRestaurantMenu