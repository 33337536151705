import { secondAdminEmail } from "Adapters/variables"
import Svgs from "Assets/svgs"

const AdminSidebarRoutes = (active, user_data) => {
  const adminDashBoard = [
    {
      url: '/admin/dashboard',
      heading: 'Dashboard',
      active: active == 'Dashboard',
      icon: <Svgs.DashbaordIcon />
    },
    {
      url: '/admin/restaurants',
      heading: 'Businesses',
      active: active == "Restaurant",
      icon: <Svgs.BuildingIcon fill={active == "Restaurant" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
    },
    {
      url: '/admin/concierges',
      heading: 'Concierges',
      active: active == 'Concierge',
      icon: <Svgs.ConciergeIcon />,
    },
    {
      url: '/admin/analytics',
      heading: 'Analytics',
      active: active == 'analytics',
      icon: <Svgs.AnalyticsIcon fill={active == "analytics" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
    },
    {
      url: '/admin/sliders',
      heading: 'Sliders',
      active: active == 'sliders',
      icon: <Svgs.RewardIcon />
    },
    // {
    //   url: '/admin/referral-analytics',
    //   heading: 'Referral Analytics',
    //   active: active == 'referral-analtics',
    //   icon: <Svgs.RewardIcon />
    // },
    {
      url: '/admin/financials',
      heading: 'Financials',
      active: active == 'financials',
      icon: <Svgs.Financials fill={active == "financials" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
      isAllowed: secondAdminEmail == user_data?.email ? true : false
    },
    // {
    //   url: '/admin/staff',
    //   heading: 'Staff',
    //   active: active == 'staff',
    //   icon: <Svgs.ProfileIcon />
    // },
    // {
    //   url: '/admin/invoice-report',
    //   heading: 'Invoice Report',
    //   active: active == 'invoice-report',
    //   icon: <Svgs.InvoiceReportIcon fill={active == "invoice-report" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/wishlist',
    //   heading: 'Wishlist',
    //   active: active == 'Wishlist',
    //   icon: <Svgs.WishlistIcon fill={active == "Wishlist" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
    //   isAllowed: secondAdminEmail == user_data?.email ? true : false
    // },
    // {
    //   url: '/admin/rewards',
    //   heading: 'Concierge Of The Month',
    //   active: active == 'reward',
    //   icon: <Svgs.RewardIcon />
    // },
    {
      url: '/admin/commissions-payments',
      heading: 'Commissions & Payments',
      active: active == 'transaction',
      icon: <Svgs.TransactionIcon />,
      isAllowed: secondAdminEmail == user_data?.email ? true : false
    },
    // {
    //   url: '/admin/export',
    //   heading: 'Export',
    //   active: active == 'export',
    //   icon: <Svgs.InvoiceReportIcon fill={active == "export" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/slots-management',
    //   heading: 'Slots Management',
    //   active: active == 'slots-management',
    //   icon: <Svgs.InvoiceReportIcon fill={active == "slots-management" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/promotion-request',
    //   heading: 'Promotion Request',
    //   active: active == 'PromotionRequest',
    //   icon: <Svgs.PromotionRequest fill={active == "PromotionRequest" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/announcement',
    //   heading: 'Announcement',
    //   active: active == 'announcement',
    //   icon: <Svgs.PromotionSvg stroke={active == "announcement" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/pay-referral-reward',
    //   heading: 'Pay Referral Reward',
    //   active: active == 'pay-referral-reward',
    //   icon: <Svgs.InvoiceReportIcon fill={active == "pay-referral-reward" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    // {
    //   url: '/admin/business-category',
    //   heading: 'Business Category',
    //   active: active == 'business-category',
    //   icon: <Svgs.InvoiceReportIcon fill={active == "business-category" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
    // },
    {
      url: '/admin/support-request',
      heading: 'Support Request',
      active: active == 'SupportRequest',
      icon: <Svgs.WishlistIcon fill={active == "SupportRequest" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />,
      isAllowed: secondAdminEmail == user_data?.email ? true : false
    },
  ]
  return adminDashBoard
}

export default AdminSidebarRoutes