import axios from "axios";
import {
    BASE_URL, add_menu_category_url, add_pdf_menu_url, add_sub_menu_url, delete_menu_category_url, get_menu_category_url, update_menu_category_url, update_menu_itm_url, delete_menu_itm_url,
    delete_pdf_menu_url,
    admin_add_sub_menu_url,
    admin_update_menu_itm_url,
    admin_delete_menu_itm_url,
    admin_delete_menu_category_url,
    admin_update_menu_category_url,
    admin_add_menu_category_url,
    admin_delete_pdf_menu_url,
    admin_add_pdf_menu_url
} from "Adapters/variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getMenuCategoreis } from "../../../Redux/Restaurant/Menu/Actions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { convertObjectToForm } from "Constants/Functions/convertDataToFormData";
import { showSingleErrorMessage } from "../Authentication";

export const getMenuCategory = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const quer = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_menu_category_url}${quer}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getMenuCategoreis(data))
        }
    } catch (error) {
        dispatch(getMenuCategoreis({ categories: [] }))
        fail && fail()
        return error;
    }
}
export const deletePDFCategory = (access_token, payload, success, fail, isAdmin) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            },
            data: payload
        }
        const url = isAdmin ? admin_delete_pdf_menu_url : delete_pdf_menu_url
        const response = await axios.delete(`${BASE_URL}${url}${payload?.id}`, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const addMenuCategory = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = payload?.isAdmin ? admin_add_menu_category_url : add_menu_category_url;

        const response = await axios.post(`${BASE_URL}${url}`, payload, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data

            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const updateMenuCategory = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = payload?.isAdmin ? admin_update_menu_category_url : update_menu_category_url;
        const response = await axios.post(`${BASE_URL}${url}/${payload?.id}`, payload, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data
            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const deleteMenuCategory = (access_token, payload, success, fail, isAdmin) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = isAdmin ? admin_delete_menu_category_url : delete_menu_category_url;
        const response = await axios.delete(`${BASE_URL}${url}/${payload}`, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}

export const addSubMenu = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = payload?.isAdmin ? admin_add_sub_menu_url : add_sub_menu_url;
        const response = await axios.post(`${BASE_URL}${url}`, payload, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data

            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const updateSubMenu = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = payload?.isAdmin ? admin_update_menu_itm_url : update_menu_itm_url;
        const response = await axios.post(`${BASE_URL}${url}/${payload?.id}`, payload, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data
            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}

export const addPdfMenu = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = payload?.isAdmin ? admin_add_pdf_menu_url : add_pdf_menu_url;
        const formData = convertObjectToForm(payload)
        const response = await axios.post(`${BASE_URL}${url}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data

            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        if (error?.response?.data?.status == 422) {
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}
export const deleteMenuItem = (access_token, payload, success, fail, isAdmin) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const url = isAdmin ? admin_delete_menu_itm_url : delete_menu_itm_url;
        const response = await axios.delete(`${BASE_URL}${url}/${payload}`, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
            // dispatch(getAllBookings(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}