import { BASE_URL, get_single_slider_url, sliders_url } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertObjectToForm } from "Constants/Functions/convertDataToFormData";
import { toast } from "react-toastify";
import { getSliders } from "../../../Redux/Admin/Slider/Action";
import { getAdminRestaurantsData } from "../../../Redux/Admin/Restaurants/Action";


// get admin slider
export const getAllSliders = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${sliders_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success?.()
            dispatch(getSliders({
                sliders: data?.sliders,
                pagination_data: data?.pagination_data?.meta
            }))
        }
    } catch (error) {
        fail?.()
        dispatch(getSliders({
            sliders: [],
            pagination_data: {}
        }))
        return error;
    }
}

// get single slider data
export const getSingleSliderData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${sliders_url}/${payload?.id}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// update or add new slider
export const updateOrAddAdminSlider = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = convertObjectToForm(payload)
    let editId = payload?.id ? `/${payload?.id}` : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        let response = await axios.post(`${BASE_URL}${sliders_url}/manage${editId}`, formData, headers);
        console.log(response, "response")
        if (response?.status == 200 || response?.status == 201) {
            success && success(response?.data)
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}

// get single slider data
export const getSingleSlider = (access_token, payload, success, fail) => {
    return async (dispatch) => {
        const updatedPayload = { ...payload };
        delete updatedPayload.slug;

        const query = updatedPayload ? buildQueryString(updatedPayload) : "";
        const slug = payload && payload.slug ? `/${payload.slug}` : "";

        try {
            const headers = {
                headers: {
                    'authorization': `Bearer ${access_token}`
                }
            };
            const response = await axios.get(`${BASE_URL}${get_single_slider_url}${slug}${query}`, headers);

            if (response && response.status === 200) {
                const data = response.data;
                if (success) success(data);

                dispatch(getAdminRestaurantsData({
                    restaurants: data?.restaurants,
                    pagination_data: data?.pagination_data
                }));
            } else {
                if (fail) fail();
            }
        } catch (error) {
            if (fail) fail(error);
            return error;
        }
    };
};
