import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Svgs from "Assets/svgs";
import NotFound from "Utility/NotFound";
import RestaurantCardV2 from "./RestaurantCardV2";
import { useNavigate } from "react-router-dom";

const RestaurantSliderV2 = ({
    loading,
    restaurants,
    isFromAdmin
}) => {
    const navigate = useNavigate()

    const sliderRef = useRef(null);
    const [slidesToShow, setSlidesToShow] = useState(5); // Default slides to show
    const [showArrows, setShowArrows] = useState(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    // Responsive settings
    const responsiveBreakpoints = [
        { breakpoint: 425, slidesToShow: 1 },
        { breakpoint: 768, slidesToShow: 3 },
        { breakpoint: 1024, slidesToShow: 4 },
    ];

    const next = () => sliderRef.current?.slickNext();
    const previous = () => sliderRef.current?.slickPrev();

    const adjustedRestaurants = restaurants?.length < 5 ? [
        ...restaurants,
        ...Array(5 - restaurants.length).fill(null),
    ] : restaurants;

    useEffect(() => {
        const updateSlidesToShow = () => {
            const screenWidth = window.innerWidth;
            const matchedBreakpoint = responsiveBreakpoints.find(bp => screenWidth <= bp.breakpoint);
            setSlidesToShow(matchedBreakpoint ? matchedBreakpoint.slidesToShow : 5);
        };

        updateSlidesToShow();
        window.addEventListener("resize", updateSlidesToShow);
        return () => window.removeEventListener("resize", updateSlidesToShow);
    }, []);

    useEffect(() => {
        setShowArrows((adjustedRestaurants?.length || 0) > slidesToShow);
    }, [adjustedRestaurants, slidesToShow]);

    const handleBeforeChange = (oldIndex, newIndex) => {
        const totalSlides = restaurants?.length || 0;
        // Disable the "Previous" button if we're at the first slide
        if (newIndex === 0) {
            setIsPrevDisabled(true);
        } else {
            setIsPrevDisabled(false);
        }

        // Disable the "Next" button if we're at the last slide
        if (totalSlides) {
            if (newIndex == totalSlides - slidesToShow) {
                setIsNextDisabled(true);
            } else {
                setIsNextDisabled(false);
            }
        } else {
            setIsNextDisabled(true);
        }
    };

    const settings = {
        dots: false,
        infinite: false, // Prevent infinite looping
        speed: 500,
        arrows: false,
        slidesToShow,
        slidesToScroll: 1,
        centerMode: false,
        beforeChange: handleBeforeChange,
        responsive: responsiveBreakpoints?.map(bp => ({
            breakpoint: bp.breakpoint,
            settings: {
                slidesToShow: bp.slidesToShow,
                centerMode: false,
                infinite: false,
            },
        })),
    };

    return (
        <div className="relative">
            <div className="slider-container overflow-hidden px-4">
                {adjustedRestaurants?.length > 0 && showArrows && (
                    <>
                        {!isPrevDisabled &&
                            <div
                                className={`cursor-pointer absolute z-[9] top-1/2 left-1 transform -translate-y-1/2 flex items-center h-10 w-10 sm:h-12 sm:w-12 justify-center shadow-lg bg-white rounded-full ${isPrevDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={isPrevDisabled ? null : previous}
                            >
                                <Svgs.BackIcon2 />
                            </div>
                        }
                        {!isNextDisabled &&
                            <div
                                className={`cursor-pointer absolute z-[9] top-1/2 right-1 transform -translate-y-1/2 flex items-center h-10 w-10 sm:h-12 sm:w-12 justify-center shadow-lg bg-white rounded-full ${isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={isNextDisabled ? null : next}
                            >
                                <Svgs.ArrowNextIcon2 />
                            </div>
                        }
                    </>
                )}
                {loading ? (
                    <Slider ref={sliderRef} {...settings}>
                        {Array.from({ length: 7 }).map((_, ind) => (
                            <div key={ind} className="slider px-2">
                                <div className="h-[180px] bg-gray-300 rounded-[20px] animate-pulse"></div>
                            </div>
                        ))}
                    </Slider>
                ) : adjustedRestaurants?.length > 0 ? (
                    <Slider ref={sliderRef} {...settings}>
                        {adjustedRestaurants?.map((itm, ind) => (
                            <div key={ind} className="slide w-fit h-full flex pr-4 pb-4">
                                {itm ? (
                                    <RestaurantCardV2
                                        title={itm?.name}
                                        image={itm?.display_picture || itm?.images?.[0]?.url || null}
                                        address={itm?.address}
                                        ratings={itm?.ratings?.find(({ type }) => type === 'google')?.rating ?? itm?.ratings?.find(({ type }) => type === 'tripadvisor')?.rating}
                                        category={itm?.categories?.[0]?.name || ''}
                                        cuisine={itm?.cuisine?.name}
                                        index={ind}
                                        showBookNow={isFromAdmin ? false : true}
                                        onCardClick={() => { navigate(`/concierge/restaurant/${itm?.id}`) }}
                                    />
                                ) : (
                                    <div className="h-[180px] w-full bg-transparent"></div>
                                )}
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <NotFound customMargin="my-0" />
                )}
            </div>
        </div>
    );
};

export default RestaurantSliderV2;
